<template>
  <div class="content">
    <div class="container-fluid animated fadeInUp">
      <div class="appList">
        <div
          class="appList-item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="appList-item-img">
            <img :src="$store.state.imgURL + item.icon_url" alt="" />
          </div>
          <div class="appList-item-text">{{ item.title }}</div>
        </div>
        <!-- <div class="appList-item">
          <div class="appList-item-img">
            <img src="../../assets/img/appList2.png" alt="" />
          </div>
          <div class="appList-item-text">组织工程</div>
        </div>
        <div class="appList-item">
          <div class="appList-item-img">
            <img src="../../assets/img/appList3.png" alt="" />
          </div>
          <div class="appList-item-text">免疫治疗</div>
        </div>
        <div class="appList-item">
          <div class="appList-item-img">
            <img src="../../assets/img/appList4.png" alt="" />
          </div>
          <div class="appList-item-text">药物筛选与开发</div>
        </div>
        <div class="appList-item">
          <div class="appList-item-img">
            <img src="../../assets/img/appList5.png" alt="" />
          </div>
          <div class="appList-item-text">研究与基础科学</div>
        </div> -->
      </div>
    </div>

    <div
      class="container-fluid gene wow animated fadeInUp"
      v-for="(item, index) in dataList"
      :key="index"
    >
      <div class="gene_left">
        <div class="gene_img">
          <img :src="$store.state.imgURL + item.img_url" alt="" />
        </div>
      </div>
      <div class="gene_right">
        <div class="gene-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>{{ item.title_small }}</span>
          </div>
          <h2>{{ item.title_long }}</h2>
          <p>
            {{ item.content }}
          </p>
          <!-- <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>再生医学：创造新生命的力量</span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>干细胞疗法：从实验室到临床 </span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>干细胞疗法：从实验室到临床 </span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>干细胞疗法：从实验室到临床 </span>
          </div> -->
          <div class="learn-more" @click="handleToDetail(item)">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="production2" class="container-fluid gene wow animated fadeInUp">
      <div class="gene_left">
        <div class="gene_img">
          <img src="../../assets/img/bz-app2.png" alt="" />
        </div>
      </div>
      <div class="gene_right">
        <div class="gene-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>组织工程（Tissue Engineering）</span>
          </div>
          <h2>重建结构 组织工程创新</h2>
          <p>
            干细胞在组织工程中可用于构建人工组织和器官。通过将干细胞与支架材料结合，可以培养出功能性的组织，如皮肤、骨骼、肌肉等，为替代性治疗提供新的选择。
          </p>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>个性化的组织工程治疗方案</span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>可持续的细胞来源</span>
          </div>
        </div>
      </div>
    </div>

    <div id="production3" class="container-fluid gene wow animated fadeInUp">
      <div class="gene_left">
        <div class="gene_img">
          <img src="../../assets/img/bz-app3.png" alt="" />
        </div>
      </div>
      <div class="gene_right">
        <div class="gene-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>免疫治疗（Immunotherapy）</span>
          </div>
          <h2>增强抵抗 免疫治疗创新</h2>
          <p>
            干细胞可以用于免疫治疗，通过增强患者的免疫系统来对抗癌症和其他疾病。干细胞免疫治疗可以改善患者的免疫功能，促进肿瘤的消退和抑制炎症反应。
          </p>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>强化免疫系统</span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>高度个体化</span>
          </div>
        </div>
      </div>
    </div>

    <div id="production4" class="container-fluid gene wow animated fadeInUp">
      <div class="gene_left">
        <div class="gene_img">
          <img src="../../assets/img/bz-app4.png" alt="" />
        </div>
      </div>
      <div class="gene_right">
        <div class="gene-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>药物筛选与开发（Drug Screening and Development）</span>
          </div>
          <h2>加速创新 药物筛选与开发</h2>
          <p>
            干细胞可以作为药物筛选和开发的工具，用于测试新药的疗效和安全性。通过在干细胞上进行药物测试，可以提高药物研发的效率和准确性，为新药的开发提供更可靠的依据。
          </p>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>高通量筛选</span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>代替动物试验</span>
          </div>
        </div>
      </div>
    </div>

    <div id="production5" class="container-fluid gene wow animated fadeInUp">
      <div class="gene_left">
        <div class="gene_img">
          <img src="../../assets/img/bz-app5.png" alt="" />
        </div>
      </div>
      <div class="gene_right">
        <div class="gene-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>研究与基础科学（Research and Basic Science）</span>
          </div>
          <h2>揭开奥秘 干细胞研究探索</h2>
          <p>
            干细胞的研究在基础科学领域具有重要意义。通过研究干细胞的特性、分化机制和调控机制，可以增进对生命本质的理解，并为未来的临床应用和医学进步提供基础。
          </p>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>细胞发育研究</span>
          </div>
          <div class="gene_item">
            <img src="../../assets/img/tick.png" alt="" />
            <span>疾病机制解析</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { GetArticleCategoryList } from "@/api/index";
export default {
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      var params = {
        page: 1,
        per_page: 100,
      };
      GetArticleCategoryList(params).then((res) => {
        this.dataList = res.data.data.data;
      });
    },
    handleToDetail(item) {
      this.$router.push({
        path: "/stemCellApplication/articleList",
        query: {
          title: item.title,
          category_id: item.category_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.appList {
  display: flex;
  margin-top: 120px;
  /* margin-bottom: 100px; */
  justify-content: center;
}
.appList-item {
  width: 220px;
  height: 170px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: -5px 5px 10px 5px #eaecf7, 5px 5px 10px 5px #eaecf7;
  text-align: center;
}
.appList-item img {
  width: 75px;
  height: 75px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.appList-item-text {
  font-size: 22px;
  font-weight: 600;
  color: #1da0a9;
}

.gene {
  height: 100%;
  padding-top: 100px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gene_right {
  margin-left: 70px;
}
.gene_img {
  width: 658px;
  height: 440px;
  border-radius: 20px;
  box-shadow: -5px 5px 10px -4px #dee4e7, 5px 5px 10px -4px #dee4e7;
}
.gene_img img {
  width: 100%;
  height: 100%;
}

.gene-content {
  width: 610px;
  height: 440px;
}

.gene-title {
  display: flex;
  align-items: center;
}

.gene-title img {
  width: 45px;
  height: 5px;
}

.gene-title span {
  font-size: 14px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.gene_right h2 {
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.gene_right p {
  margin: 0;
  padding: 0;
  height: 80px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #005a7e;
  letter-spacing: 1px;
}
.gene_item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.gene_item img {
  width: 20px;
  height: 20px;
}
.gene_item span {
  font-size: 18px !important;
  color: #000;
  font-weight: bolder;
  letter-spacing: 1px;
  padding-left: 10px;
  cursor: pointer;
}
.gene_item span:hover {
  color: #409eff;
}
</style>