<template>
  <div>
    <div id="qrcode-container" v-show="is_Show">
      <div id="qrcode-toggle">
        <span @click="close">关闭</span>
      </div>
      <div id="qrcode">
        <img src="../../assets/img/bz.jpg" />
        <p>北正细胞</p>
      </div>
    </div>
    <div class="weChart" @click="open" v-show="!is_Show">
      <p>北</p>
      <p>正</p>
      <p>公</p>
      <p>众</p>
      <p>号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "we-code",
  data() {
    return {};
  },
  computed: {
    is_Show: function () {
      return this.$store.state.isWeCode;
    },
  },
  methods: {
    close() {
      this.$store.commit("updateWeCode", false);
    },
    open() {
      this.$store.commit("updateWeCode", true);
    },
  },
};
</script>

<style scoped>
.weChart {
  position: fixed;
  right: 0;
  width: 30px;
  top: 70%;
  text-align: center;
  transform: translateY(-50%);
  background-color: #1da2a9;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}
.weChart p {
  margin: 0;
}
#qrcode-container {
  position: fixed;
  right: 20px;
  top: 70%;
  transform: translateY(-50%);
  background-color: #fff;
  /* padding: 10px; */
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow: hidden;
}

#qrcode-toggle {
  cursor: pointer;
  text-align: right;
  margin-bottom: 5px;
  background: #1da2a9;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#qrcode {
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#qrcode img {
  width: 120px;
}

#qrcode p {
  font-size: 14px;
  margin: 0;
}

</style>