<template>
  <div class="header">
    <div class="container-fluid">
      <div class="hide-part-1">
        <div class="row pt-1 pb-1">
          <div class="nav-left col-sm-2">
            <img src="../../assets/img/bz-logo.png" class="mx-auto d-block logo" />
          </div>
          <div class="top-menu col-sm-10">
            <router-link class="top-item2" to="/home">首页</router-link>
            <router-link class="top-item2" to="/stemCellView">干细胞存储</router-link>
            <div :class="isActive
              ? 'top-item2 top-menus top-menus-active'
              : 'top-item2 top-menus'
              " @click="toStemCell" to="/stemCellApplication">
              细胞应用
              <div class="top-show" v-if="dataList.length > 1">
                <div class="top-menus-list">
                  <div class="top-menu-item" v-for="(item, index) in dataList" :key="index"
                    @click.stop="toArticleList(item)">{{ item.title }}</div>
                </div>
              </div>
            </div>
            <router-link class="top-item2" to="/aboutUs">关于我们</router-link>
            <router-link class="top-item2" to="/resource">北正资源</router-link>
            <router-link class="top-item2" to="/health">健康管理</router-link>
            <router-link class="top-item2" to="/finance">财富投资</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetArticleCategoryList } from "@/api/index";
export default {
  data() {
    return {
      isActive: false,
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    let url = window.location.href;
    if (url.indexOf("stemCellApplication") != -1) {
      this.isActive = true;
    }
  },
  methods: {
    getData() {
      var params = {
        page: 1,
        per_page: 100,
      };
      GetArticleCategoryList(params).then((res) => {
        this.dataList = res.data.data.data;
      });
    },
    handleToDetail() {
      this.$router.push("/detail");
    },
    toStemCell() {
      this.$router.push({
        path: "/stemCellApplication/index",
      });
    },
    toArticleList(item) {
      this.$router.push({
        path: "/stemCellApplication/articleList",
        query: {
          title: item.title,
          category_id: item.category_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  /* position: relative; */
  background-color: #fff;
}

.art {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.img-responsive {
  position: relative;
  max-width: 100%;
  height: auto;
}

.head_art {
  background: #fff;
  text-align: center;
  width: 370px;
  height: 160px;
  border-radius: 10px;
  cursor: pointer;
  padding: 0;
  box-shadow: -5px 5px 10px -4px #dfe4ea, 5px 5px 10px -4px #dfe4ea;
}

.head_art dl {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0;
}

.head_art dl dt {
  width: 100px;
  text-align: center;
}

.head_art dl dd {
  margin: 0;
  text-align: left;
}

.head_art dl img {
  width: 60px;
  height: 60px;
}

.head_art dl h3 {
  margin: 0;
  line-height: 40px;
  font-size: 22px;
  color: #025a80;
  font-weight: 600;
  padding-bottom: 10px;
}

.head_art dl p {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
}

.head_art:hover {
  background: #1da2ab;
}

.head_art:hover h3 {
  color: #fff;
}

.head_art:hover p {
  color: #fff;
}

.head_art:hover .stem {
  content: url(../../assets/img/stems.svg);
}

.head_art:hover .device {
  content: url(../../assets/img/devices.png);
}

.head_art:hover .treat {
  content: url(../../assets/img/treats.png);
}

.logo {
  width: 150px;
  height: auto;
}

.top-menu {
  text-align: center;
  display: flex;
  align-items: center;
}

.top-item2 {
  font-size: 24px;
  font-weight: 600;
  color: #015b7e;
  text-decoration: none;
  padding: 15px 38px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.top-menu-item {
  font-size: 18px;
  font-weight: bolder;
  color: #015b7e;
  text-decoration: none;
  padding: 12px 25px;
  cursor: pointer;
}

.top-menus-active {
  background: #1da2a9;
  color: #fff;
}

.top-menu-item:hover {
  background: #1da2a9;
  color: #fff;
}

.top-item2:hover {
  background: #1da2a9;
  color: #fff;
}

.router-link-active {
  background: #1da2a9;
  color: #fff;
}

.hide-part-1 {
  white-space: nowrap;
}

.hide-part-2 {
  white-space: nowrap;
}

.text-hero {
  position: absolute;
  padding-left: 10%;
  text-align: left;
  color: #fff;
  top: 30%;
}

.text-hero-h1 {
  font-weight: 900;
  font-size: 44px;
  line-height: 80px;
  color: #005a7f;
  margin: 0;
}

.text-hero-h2 {
  font-weight: 500;
  font-size: 42px;
  line-height: 80px;
  color: #005a7f;
  margin: 0;
}

.description-hero {
  line-height: 30px;
  font-size: 14px;
  margin: 0;
}

.top-menus {
  position: relative;
}

.top-show {
  display: none;
}

.top-menus:hover .top-show {
  display: block;
}

.top-menus-list {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  translate: -50% 100%;
  background: #fff;
  z-index: 999999999999999999;
  border-radius: 6px;
  box-shadow: 0 0 5px #ababab;
  margin-left: 50%;
  /* margin-top: 20px; */
}

.top-menus-list:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -9px;
  left: calc(50% - 15px);
  z-index: 3;
}

.top-menus-list:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 10px solid #ababab;
  position: absolute;
  top: -10px;
  left: calc(50% - 15px);
}
</style>