<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>
    <!-- header -->
    <Navbar></Navbar>
    <div class="banner">
      <img
        class="banner-img"
        src="../../assets/img/stemCellBanner.png"
        alt=""
      />
      <div class="head-card">
        <h3>珍藏健康 预见未来</h3>
        <h6>选择我们的干细胞存储服务，为您和您家人的健康提供最珍贵的保障！</h6>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid gene animated adeOutRight">
        <!-- <h4>干细胞存储</h4> -->
        <div class="gene_left">
          <div class="gene_img"></div>
        </div>
        <div class="gene_right">
          <div class="gene-content">
            <div class="gene-title">
              <img src="../../assets/img/bm.png" />
              <span>STORAGE SERVICE OVERVIEW</span>
            </div>
            <h2>保护未来 珍藏健康</h2>
            <p>
              为您和您家人的未来，我们为您提供独一无二的干细胞存储服务。在干细胞的世界中，蕴藏着改变生命的奇迹和无限的可能性。选择我们的干细胞存储服务，您将拥有最宝贵的健康保障。
            </p>
            <p>
              我们致力于提供安全可靠的干细胞存储，为您的家庭提供持久的健康盾牌。我们采用最先进的技术和严格的质量控制标准，将您的宝贵干细胞储存起来，并为其提供最优的保存环境。这样，无论何时何地，您都可以信心满满地依靠干细胞的力量，让健康成为您生活中最可靠的伴侣。
            </p>
            <div class="gene_dl">
              <dl>
                <dt>
                  <img src="../../assets/img/stemcell4.png" alt="" />
                </dt>
                <dd>
                  <h5>先进的技术保障</h5>
                  <h6>确保干细胞存储的安全可靠性</h6>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src="../../assets/img/stemcell3.png" alt="" />
                </dt>
                <dd>
                  <h5>专业团队护航</h5>
                  <h6>精心呵护每个细胞的干细胞存储</h6>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="content">
      <div class="container-fluid storage wow animated fadeInUp">
        <div class="storage-left">
          <div class="storage-img">
            <img src="../../assets/img/storage1.png" alt="" />
          </div>
        </div>
        <div class="storage-right">
          <div class="storage-mark">
            <h4>免疫细胞存储</h4>
            <h2>存储免疫细胞 守护健康生活</h2>
            <p>
              将 “年轻” 时健康、强大的 “免疫力”
              存储起来，当机体出现健康状况时，可以将储存的“免疫力”重新输入机体，从而达到免疫功能重建的目的，对恶性肿瘤的预防与治疗具有显著的功效。
            </p>
            <div @click="handleToShop" class="learn-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid storage wow animated fadeInUp">
        <div class="storage-left">
          <div class="storage-img">
            <img src="../../assets/img/storage2.png" alt="" />
          </div>
        </div>
        <div class="storage-right">
          <div class="storage-mark">
            <h4>脐带间充质干细胞存储</h4>
            <h2>存储今天的细胞种子 留住明天健康希望</h2>
            <p>
              间充质干细胞属于多功能干细胞，具有干细胞增生及多向分化的能力，可分化成身体所需的神经细胞、血管细胞或脂肪细胞等。
            </p>
            <div @click="handleToShop2" class="learn-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid storage wow animated fadeInUp">
        <div class="storage-left">
          <div class="storage-img">
            <img src="../../assets/img/storage3.png" alt="" />
          </div>
        </div>
        <div class="storage-right">
          <div class="storage-mark">
            <h4>胎盘多能干细胞存储</h4>
            <h2>存储今天细胞种子 留住明天健康希望</h2>
            <p>
              胎盘间充质干细胞具有更广泛的分化能力，不仅能够分化为基本的脂肪细胞、软骨细胞和成骨细胞，还能够分化为各种神经细胞谱系和心肌细胞
            </p>
            <div @click="handleToShop3" class="learn-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid storage wow animated fadeInUp">
        <div class="storage-left">
          <div class="storage-img">
            <img src="../../assets/img/storage4.png" alt="" />
          </div>
        </div>
        <div class="storage-right">
          <div class="storage-mark">
            <h4>牙髓间充质干细胞存储</h4>
            <h2>存储牙髓干细胞 守护健康生活</h2>
            <p>
              牙髓干细胞指的是牙髓组织中存在的一类既能保持自身未分化状态和增殖能力，又可在适宜条件下分化为多种功能细胞或组织器官的间充质干细胞相比其他成体干细胞，牙髓干细胞可以从拔除的智齿、脱落的乳牙的牙髓中获取，其来源丰富、采集方便、免疫原性低，不仅在再生医学和组织工程修复中有着强大的应用潜力，还能为未来的牙病治疗提供一种优质选择。自2000年人类发现牙髓干细胞以来10多年间牙髓干细胞已经成为各国研究的重点和热点。
            </p>
            <div @click="handleToShop4" class="learn-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid storage wow animated fadeInUp">
        <div class="storage-left">
          <div class="storage-img">
            <img src="../../assets/img/storage5.png" alt="" />
          </div>
        </div>
        <div class="storage-right">
          <div class="storage-mark">
            <h4>脂肪间充质干细胞存储</h4>
            <h2>存储脂肪干细胞 守护健康生活</h2>
            <p>
              脂肪间充质干细胞(Adipose-Derived Stem
              Cells,ADSCs)是从脂肪组织中分离得到的一种具有多向分化潜能的干细胞具有自我更新和多向分化能力。脂肪干细胞被称为“人体软黄金”。
            </p>
            <div @click="handleToShop5" class="learn-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid tpas wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>TORAGE PROCEDURES AND STANDARDS</span>
        </div>
        <h3 class="pp-subtitle">科技呵护 精心保存</h3>
        <p class="pp-mark">
          我们严格按照最高质量标准，为您的干细胞提供细致呵护和安全保存。我们采用先进的采集技术和标准化的处理流程，确保每一个细胞都得到最佳的保护和保存。
        </p>
        <div class="flow">
          <div class="flow-item1 flow-item">
            <h4>采集与存储</h4>
            <p>
              我们通过专业的医疗团队采集合适的干细胞样本，采集方式根据干细胞类型和用途而定，确保收集到的样本质量优良。
            </p>
          </div>
          <div class="flow-item2 flow-item">
            <h4>提取与分离</h4>
            <p>
              经过精心处理，我们将样本进行细胞分离和提取，确保干细胞的纯度和生物活性，为后续的存储做好准备。
            </p>
          </div>
          <div class="flow-item3 flow-item">
            <h4>冻存与安全保存</h4>
            <p>
              我们采用先进的冷冻技术和优化的保存环境，将干细胞样本进行冷冻保存，确保样本的长期安全储存和生物活性的保持。
            </p>
          </div>
          <div class="flow-item4 flow-item">
            <h4>标识与记录</h4>
            <p>
              我们为每个样本分配唯一的标识号码，并建立详细的记录，确保样本的可追溯性和安全性，为后续的使用提供便利和保障。
            </p>
          </div>
        </div>
      </div>
    </div>

    <weCode></weCode>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/headers";
import Footer from "@/components/footer";
export default {
  components: { Navbar, Footer },
  data() {
    return {
      bz_url: "https://item.jd.com/10081502130560.html",
    };
  },
  methods: {
    handleToShop() {
      window.open(this.bz_url);
    },
    handleToShop2() {
      window.open("https://item.jd.com/10081504518267.html");
    },
    handleToShop3() {
      window.open("https://item.jd.com/10081503905916.html");
    },
    handleToShop4() {
      window.open("https://item.jd.com/10081830792484.html");
    },
    handleToShop5() {
      window.open("https://item.jd.com/10081651821533.html");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
};
</script>
<style scoped>
@import "./stemCell.css";
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 385px;
  position: relative;
}
.banner-img {
  width: 100%;
  height: 100%;
}
.head-card {
  width: 1330px;
  height: 150px;
  position: absolute;
  background: url(../../assets/img/banner-bg.png) no-repeat;
  top: 125px;
  left: 0;
}
.head-card h3 {
  margin: 0;
  font-size: 54px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
  padding-top: 20px;
  padding-left: 355px;
}
.head-card h6 {
  font-size: 24px;
  color: #fff;
  padding-top: 15px;
  padding-left: 355px;
}
.gene {
  height: 100%;
  padding-top: 160px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}
.gene_right {
  margin-left: 70px;
}
.gene_img {
  width: 650px;
  height: 600px;
  background: url(../../assets/img/stemCell2.png) no-repeat;
  background-size: 100% 100%;
}

.gene-content {
  width: 610px;
  height: 540px;
  /* padding-top: 50px; */
}

.gene-title {
  display: flex;
  align-items: center;
}

.gene-title img {
  width: 45px;
  height: 5px;
}

.gene-title span {
  font-size: 14px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.gene_right h2 {
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 65px;
  padding-bottom: 25px;
}

.gene_right p {
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
  letter-spacing: 2px;
}
.gene_dl {
  display: flex;
}

.gene_dl dl {
  width: 250px;
  margin-left: 50px;
  margin-bottom: 0;
  margin-top: 120px;
  text-align: center;
}
.gene_dl dl dt {
  width: 95px;
  height: 95px;
  /* line-height: 95px; */
  text-align: center;
  background-color: #cfd4d8;
  border-radius: 50%;
  margin: 0 auto;
}
.gene_dl dl dt img {
  width: 50px;
  height: 50px;
  margin-top: 22px;
}
.gene_dl dl dd h5 {
  font-size: 22px;
  color: #005a7e;
  margin: 0;
  margin-top: 20px;
  /* width: 220px; */
}

.gene_dl dl dd h6 {
  font-size: 12px;
  color: #005a7e;
  margin: 0;
  margin-top: 10px;
  /* width: 220px; */
}

.tpas {
  width: 100%;
  height: 1060px;
  background-color: #cddcdc;
  margin-top: 40px;
  /* margin-bottom: 40px; */
}
.flow {
  width: 1100px;
  height: 500px;
  margin: 0 auto;
  margin-top: 100px;
  position: relative;
  background: url(../../assets/img/tpas.png) no-repeat;
  background-size: 100% auto;
}
.flow-item {
  position: absolute;
}
.flow-item1 {
  top: 190px;
}
.flow-item2 {
  top: 340px;
  left: 290px;
}
.flow-item3 {
  top: 190px;
  left: 610px;
}
.flow-item4 {
  top: 270px;
  left: 900px;
}
.flow-item h4 {
  font-size: 24px;
  font-weight: 600;
  color: #005a7e;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.flow-item p {
  width: 180px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
}

.pp-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.pp-title img {
  width: 45px;
  height: 5px;
}

.pp-title span {
  font-size: 16px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.pp-subtitle {
  font-size: 34px;
  color: #005a7e;
  text-align: center;
  font-weight: bolder;
  margin: 0;
  margin-top: 25px;
  margin-bottom: 25px;
}

.pp-mark {
  margin: 0 auto;
  font-size: 12px;
  line-height: 20px;
  width: 540px;
  text-align: center;
  font-weight: 600;
}

.storage {
  width: 100%;
  height: 480px;
  margin-top: 25px;
  /* background-color: #cddcdc; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.storage-left {
  margin-right: 50px;
}

.storage-right {
  margin-left: 50px;
}

.storage-img {
  width: 550px;
  height: 435px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: -5px 5px 10px 5px #eaecf7, 5px 5px 10px 5px #eaecf7;
}

.storage-img img {
  width: 100%;
  height: 100%;
}

.storage-mark {
  height: 335px;
  /* line-height: 335px; */
}

.storage-mark h4 {
  font-size: 24px;
  font-weight: 600;
  color: #005a7e;
  margin: 0;
  height: 30px;
}

.storage-mark h2 {
  font-size: 32px;
  height: 50px;
  font-weight: 600;
  color: #005a7e;
  margin: 0;
  margin-top: 22px;
  margin-bottom: 25px;
}

.storage-mark p {
  margin: 0;
  width: 580px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.learn-more {
  width: 160px;
  height: 50px;
  background-color: #1d838b;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 45px;
}

.learn-more span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
  padding-right: 40px;
}

.learn-more .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.contact {
  width: 100%;
  height: 470px;
  text-align: center;
}

.contact h2 {
  font-size: 32px;
  color: #005a7e;
  font-weight: 600;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.userInfo {
  display: flex;
  justify-content: center;
}

.userInfo p {
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.userInfo input {
  width: 410px;
  height: 64px;
  background-color: #e5e5e5;
  border: none;
  padding-left: 20px;
  font-size: 14px;
  outline: none;
}

.phone {
  margin-left: 15px;
}

.consult {
  width: 100%;
  height: 450px;
  background: url(../../assets/img/consult.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consult h4 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  font-weight: 600;
  margin-top: 20px;
  margin-right: 400px;
}
</style>