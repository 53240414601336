import http from './public'


// // 获取文章分类列表
// export const GetArticleCategoryList = (params) => {
//     return http.fetchPost('/admin/article_category/listArticleCategory', params)
// }
// // 新增文章分类
// export const AddArticleCategory = (params) => {
//     return http.fetchPost('/admin/article_category/addArticleCategory', params)
// }
// // 编辑文章分类状态
// export const EditArticleCategoryStatus = (params) => {
//     return http.fetchPost('/admin/article_category/editArticleCategoryStatus', params)
// }
// // 编辑文章分类
// export const EditArticleCategory = (params) => {
//     return http.fetchPost('/admin/article_category/editArticleCategory', params)
// }
// // 获取文章列表
// export const GetArticleList = (params) => {
//     return http.fetchPost('/admin/article/listArticle', params)
// }
// // 新增文章
// export const AddArticle = (params) => {
//     return http.fetchPost('/admin/article/addArticle', params)
// }
// // 编辑文章
// export const EditArticle = (params) => {
//     return http.fetchPost('/admin/article/editArticle', params)
// }
// // 获取文章详情
// export const GetArticleDetail = (params) => {
//     return http.fetchPost('/admin/article/getArticle', params)
// }
// // 编辑文章状态
// export const EditArticleStatus = (params) => {
//     return http.fetchPost('/admin/article/editArticleStatus', params)
// }



// 用户端

// 获取轮播图列表
export const GetBannerList = (params) => {
    return http.fetchPost('/api/carousel_img/listCarouselImg', params)
}
// 获取文章分类列表
export const GetArticleCategoryList = (params) => {
    return http.fetchPost('/api/article_category/listArticleCategory', params)
}
// 获取文章列表
export const GetArticleList = (params) => {
    return http.fetchPost('/api/article/listArticle', params)
}
// 获取文章详情
export const GetArticleDetail = (params) => {
    return http.fetchPost('/api/article/getArticle', params)
}