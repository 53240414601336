<template>
  <!-- footer -->
  <footer>
    <div class="container-fluid footer">
      <div class="footer-container wow animated fadeInUp">
        <div class="row footer-main">
          <div class="col-md-4 col-sm-8 text-footer">
            <img
              src="../../assets/img/footer-logo.png"
              class="footerLogo"
              alt=""
            />
            <p>
              北正赛欧(北京)
              生物科技有限公司是一家在生命健康领域以技术创新为导向，以模式创新为核心致力于前沿生物科研与应用的国家高新技术企业。
            </p>
            <!-- <div class="footer-more">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div> -->
          </div>
          <div class="col-md-2 col-sm-8 text-footer">
            <h5>专业服务</h5>
            <ul class="list-unstyled">
              <li><a href="#" class="text-white">细胞创新药研发</a></li>
              <li><a href="#" class="text-white">细胞技术研究</a></li>
              <li><a href="#" class="text-white">细胞存储服务</a></li>
              <li><a href="#" class="text-white">抗衰及医美产品开发</a></li>
              <!-- <li><img src="../../assets/img/bz.jpg" class="nfr" alt=""><a href="#" class="text-white text-nfr">北正细胞</a></li> -->
            </ul>
          </div>
          <div class="col-md-2 col-sm-8 text-footer">
            <h5>企业介绍</h5>
            <ul class="list-unstyled">
              <li><a href="#" class="text-white">关于我们</a></li>
              <li><a href="#" class="text-white">专业优势</a></li>
              <li><a href="#" class="text-white">专利及荣誉</a></li>
              <li><a href="#" class="text-white">专家团队</a></li>
              <!-- <li><img src="../../assets/img/shop.png" class="nfr" alt=""><a href="#" class="text-white text-nfr">北正商城</a></li> -->
            </ul>
          </div>
          <div class="col-md-4 col-sm-8 text-footer">
            <h5>联系我们</h5>
            <p>如果您对我们的技术、产品或服务感兴趣，请随时联系我们。</p>
            <p><img src="../../assets/img/phone.png" alt="" />8610-80765233</p>
            <p>
              <img
                src="../../assets/img/address.png"
                alt=""
              />北京市昌平区生命园中路8号一区10号楼4层
            </p>
            <p>
              <img
                src="../../assets/img/email.png"
                alt=""
              />beizhengsc@bz-sc.com
            </p>
          </div>
        </div>
        <div class="footer-copy">
          版权所有 @北正赛欧(北京)生物科技有限公司 京ICP备2022014291号-1
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background-color: #105f7d;
}

.footer-container {
  padding-left: 10%;
  padding-right: 10%;
}

.footer-main {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 40px;
  margin: 0 !important;
}

.footer-main h5 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  padding-bottom: 20px;
}

.footerLogo {
  width: 150px;
  height: auto;
  margin-bottom: 25px;
}

.footer-main p {
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  font-weight: bolder;
  color: #fff;
  letter-spacing: 2px;
}

.footer-main p img {
  width: 30px;
  height: auto;
  margin-right: 8px;
}

.footer-main a {
  text-decoration: none;
  line-height: 40px;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 2px;
}

.text-footer {
  padding: 0 !important;
}

.text-footer:first-child {
  padding-right: 70px !important;
}

.list-unstyled {
  margin-bottom: 0;
}
.nfr{
  width: 100px;
  height: 100px;
  display: block;
  margin-top: 10px;
}
.text-nfr{
  text-align: center;
  width: 100px;
  display: block;
  align-items: center;
}

.footer-copy {
  line-height: 85px;
  width: 100%;
  border-top: 2px solid #95bfcd;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.footer-more {
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.footer-more span {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.footer-more .iconRight {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
</style>