import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isWeCode: true,
    imgURL:'https://www-api.beizhengc.com'
  },
  getters: {
  },
  mutations: {
    updateWeCode(state, isWeCode) {
      state.isWeCode = isWeCode
    }
  },
  actions: {
  },
  modules: {
  }
})
