<template>
  <div class="header">
    <div class="container-fluid">
      <div class="hide-part-1">
        <div class="row pt-1 pb-1">
          <div class="nav-left col-sm-2">
            <img
              src="../../assets/img/bz-logo.png"
              class="mx-auto d-block logo"
            />
          </div>
          <div class="top-menu col-sm-10">
            <router-link class="top-item2 active" to="/">首页</router-link>
            <router-link class="top-item2" to="/stemCellView"
              >干细胞存储</router-link
            >
            <div
              class="top-item2 top-menus"
              @click="toStemCell"
              to="/stemCellApplication"
            >
              细胞应用
              <div class="top-show" v-if="dataList.length > 1">
                <div class="top-menus-list">
                  <div
                    class="top-menu-item"
                    v-for="(item, index) in dataList"
                    :key="index"
                    @click.stop="toArticleList(item)"
                  >{{ item.title }}</div>
                </div>
              </div>
            </div>
            <router-link class="top-item2" to="/aboutUs">关于我们</router-link>
            <router-link class="top-item2" to="/resource">北正资源</router-link>
            <router-link class="top-item2" to="/health">健康管理</router-link>
            <router-link class="top-item2" to="/finance">财富投资</router-link>
          </div>
        </div>
      </div>
    </div>
    <el-carousel class="img-responsive">
      <el-carousel-item
        class="img-item"
        v-for="(item, index) in imgList"
        :key="index"
      >
        <img :src="$store.state.imgURL + item.img_url" />
      </el-carousel-item>
    </el-carousel>
    <div class="container-fluid head_content">
      <div class="art wow animated fadeInDown">
        <div class="head_art">
          <dl>
            <dt>
              <img class="stem" src="../../assets/img/stem.png" alt="" />
            </dt>
            <dd>
              <h3>独立技术优势</h3>
              <p>干细胞治疗领域</p>
              <p>拥有独立的技术优势</p>
            </dd>
          </dl>
        </div>
        <div class="head_art">
          <dl>
            <dt>
              <img class="device" src="../../assets/img/device.png" alt="" />
            </dt>
            <dd>
              <h3>先进设备和技术</h3>
              <p>实验室采用</p>
              <p>最先进的技术</p>
            </dd>
          </dl>
        </div>
        <div class="head_art">
          <dl>
            <dt>
              <img class="treat" src="../../assets/img/treat.png" alt="" />
            </dt>
            <dd>
              <h3>高水准医疗技术保障</h3>
              <p>依托北大医疗产业园</p>
              <p>和相关专家支持</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetBannerList, GetArticleCategoryList } from "@/api/index";
export default {
  data() {
    return {
      imgList: [],
      dataList: [],
    };
  },
  created() {
    this.getBanner();
    this.getData();
  },
  methods: {
    getData() {
      var params = {
        page: 1,
        per_page: 100,
      };
      GetArticleCategoryList(params).then((res) => {
        this.dataList = res.data.data.data;
      });
    },
    getBanner() {
      let params = {
        location: 1,
      };
      GetBannerList(params).then((res) => {
        console.log(res);
        this.imgList = res.data.data.data;
      });
    },
    toStemCell() {
      this.$router.push({
        path: "/stemCellApplication/index",
      });
    },
    toArticleList(item) {
      this.$router.push({
        path: "/stemCellApplication/articleList",
        query: {
          title: item.title,
          category_id: item.category_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  position: relative;
  /* background-color: #c0dee8; */
}
.head_content {
  position: absolute;
  width: 100%;
  bottom: -80px;
  z-index: 999999;
}

.art {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}
.img-responsive {
  position: relative;
  width: 100%;
  height: 880px;
  margin: 0;
}
::v-deep .el-carousel__container {
  height: 100% !important;
}
.img-item {
  width: 100%;
  height: 880px;
}
.img-responsive img {
  width: 100%;
  height: 100%;
}
.head_art {
  background: #fff;
  text-align: center;
  width: 370px;
  height: 160px;
  border-radius: 10px;
  cursor: pointer;
  padding: 0;
  box-shadow: -5px 5px 10px -4px #dfe4ea, 5px 5px 10px -4px #dfe4ea;
}

.head_art dl {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0;
}

.head_art dl dt {
  width: 100px;
  text-align: center;
}

.head_art dl dd {
  margin: 0;
  text-align: left;
}

.head_art dl img {
  width: 60px;
  height: 60px;
}

.head_art dl h3 {
  margin: 0;
  line-height: 40px;
  font-size: 22px;
  color: #025a80;
  font-weight: 600;
  padding-bottom: 10px;
}

.head_art dl p {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
}

.head_art:hover {
  background: #1da2ab;
}

.head_art:hover h3 {
  color: #fff;
}

.head_art:hover p {
  color: #fff;
}

.head_art:hover .stem {
  content: url(../../assets/img/stems.svg);
}

.head_art:hover .device {
  content: url(../../assets/img/devices.png);
}

.head_art:hover .treat {
  content: url(../../assets/img/treats.png);
}

.logo {
  width: 150px;
  height: auto;
}

.top-menu {
  text-align: center;
  display: flex;
  align-items: center;
}

.top-item2 {
  font-size: 24px;
  font-weight: 600;
  color: #015b7e;
  text-decoration: none;
  padding: 15px 38px;
  margin-left: 10px;
  border-radius: 5px;
}
.top-menu-item {
  font-size: 18px;
  font-weight: bolder;
  color: #015b7e;
  text-decoration: none;
  padding: 12px 25px;
  cursor: pointer;
}
.top-menu-item:hover {
  background: #1da2a9;
  color: #fff;
}

.top-item2:hover {
  /* background: #fff; */
  background: #1da2a9;
  color: #fff;
}
.active {
  background: #1da2a9;
  color: #fff;
}

.hide-part-1 {
  white-space: nowrap;
  /* padding-bottom: 20px; */
}

.hide-part-2 {
  white-space: nowrap;
}
.text-hero {
  position: absolute;
  padding-left: 15%;
  text-align: left;
  color: #fff;
  top: 30%;
  z-index: 99999;
}

.text-hero-h1 {
  font-weight: 900;
  font-size: 44px;
  line-height: 80px;
  color: #005a7f;
  margin: 0;
}

.text-hero-h2 {
  font-weight: 500;
  font-size: 42px;
  line-height: 80px;
  color: #005a7f;
  margin: 0;
}

.description-hero {
  line-height: 30px;
  font-size: 14px;
  margin: 0;
}

.learn-more {
  width: 200px;
  height: 60px;
  margin-top: 40px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.learn-more span {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
  padding-left: 45px;
  padding-right: 30px;
}

.learn-more .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
}

.learn-more:hover {
  background-color: #1da2ab;
}

.learn-more:hover span {
  color: #fff;
}

.learn-more:hover .iconRight {
  color: #fff;
}
.top-menus {
  position: relative;
}
.top-show {
  display: none;
}
.top-menus:hover .top-show {
  display: block;
}
.top-menus-list {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  translate: -50% 100%;
  background: #fff;
  z-index: 999999999999999999;
  border-radius: 6px;
  box-shadow: 0 0 5px #ababab;
  margin-left: 50%;
  /* margin-top: 20px; */
}
.top-menus-list:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -9px;
  left: calc(50% - 15px);
  z-index: 3;
}
.top-menus-list:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 10px solid #ababab;
  position: absolute;
  top: -10px;
  left: calc(50% - 15px);
}
</style>