<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>

    <!-- header -->
    <Navbar></Navbar>

    <!-- content -->
    <div class="content">
      <div class="container-fluid gene">
        <div class="gene_left wow animated fadeInLeft">
          <div class="gene_img">
            <div class="gene_detail">
              <img class="service" src="../../assets/img/gened.png" alt="" />
              <h3>产品与服务</h3>
              <!-- <div class="learn-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div> -->
            </div>
          </div>
        </div>
        <div class="gene_right wow animated fadeInRight">
          <div class="gene-content">
            <div class="gene-title">
              <img src="../../assets/img/bm.png" />
              <span>WHO WE ARE</span>
            </div>
            <h2>提供安全有效的</h2>
            <h2>干细胞技术与临床产品</h2>
            <p>
              北正赛欧 (北京)
              生物科技有限公司是一家以技术创新为导向的国家高新技术企业，专注于前沿生物科研与应用。公司成立于2014年，位于北京中关村生命科学园核心区，拥有国内外专家组成的专家科研团队。公司致力于为生命科学研究和再生医学应用技术的研发与产业化提供基础，并提供安全有效的干细胞技术及各种临床产品、衍生物的生物制品，以及提供与人体具有相融性的生物材料、修复再生的组织工程产品。
            </p>
            <div class="gene-mv">
              <!-- <img src="../../assets/img/mvBtns.png" alt="" />
              <span>HOW WE WORK</span> -->
            </div>
            <div class="gene-purpose">
              <h5>公司宗旨</h5>
              <p>安全、有效、专注</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="preparation">
      <div class="container-fluid wow animated fadeInDown">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>PREPARATION PROCESS</span>
        </div>
        <h3 class="pp-subtitle">制备工艺优势</h3>
        <p class="pp-mark">
          高标准干细胞实验室：先进设备、符合质控标准、精准服务保障
        </p>
      </div>
      <div class="pp-content wow animated fadeInUp">
        <div class="pp-item">
          <img class="stem" src="../../assets/img/stem.png" alt="" />
          <h4>拥有独立的技术优势</h4>
          <p>
            公司拥有独立的技术优势，包括细胞制备技术超前、细胞产出效率高、无需配型等，使得公司制备的细胞纯度高、活性高、通用性好。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
        <div class="pp-item">
          <img class="patent" src="../../assets/img/patent.svg" alt="" />
          <h4>多项发明专利技术</h4>
          <p>
            公司拥有多项发明专利技术,并解决了细胞制备方法中能获得高纯度、均一性好的细胞的行业难题。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
        <div class="pp-item">
          <img class="lab" src="../../assets/img/lab.png" alt="" />
          <h4>高标准的干细胞研发实验室</h4>
          <p>
            公司建有高标准的干细胞研发实验室，采用最先进的设备和技术，符合质控标准，保证细胞品质均一且产量高。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
        <div class="pp-item">
          <img class="team" src="../../assets/img/team.png" alt="" />
          <h4>资深的技术团队</h4>
          <p>
            公司拥有资深的技术团队，包括细胞生物学、基础医学和临床医学的博士、硕士，并依托北大医疗产业园及相关专家支持，在细胞治疗等领域具备较高的技术实力。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
        <div class="pp-item">
          <img class="thinking" src="../../assets/img/thinking.png" alt="" />
          <h4>致力于创新和新思路</h4>
          <p>
            公司致力于创新和新思路，在多方面专家支持的紧密协作下，不断研发出更好的细胞制备、储存、使用方法，以提高干细胞治疗的效果和安全性。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
        <div class="pp-item">
          <img class="secure" src="../../assets/img/secure.png" alt="" />
          <h4>提供精准的服务保障</h4>
          <p>
            公司提供精准的服务保障，为研究更多拥有自主知识产权的高品质品种，对细胞应用提供精准的服务保障。
          </p>
          <!-- <div class="pp-learn-more">
            <span>了解更多</span>
            <i class="el-icon-right iconRight"></i>
          </div> -->
        </div>
      </div>
    </div>

    <div class="bz-detail">
      <div class="core">
        <div class="core-left wow animated fadeInLeft">
          <div class="core-title">
            <img src="../../assets/img/bm.png" alt="" />
            <span>CORE TECHNICAL</span>
          </div>
          <h3 class="core-subtitle">核心技术先进 制备的细胞优点多</h3>
          <h3 class="core-subtitle">产量高 领先行业</h3>
          <p class="core-mark">
            有多项核心技术，决定了公司制备的细胞纯度高、活性高，通用性好，使用时无需配型。并且细胞制备技术超前，细胞产出效率远远高出同类水平，在行业中脱颖而出。
          </p>
        </div>
        <div class="core-right wow animated fadeInRight">
          <img src="../../assets/img/core.png" alt="" />
        </div>
      </div>
      <!-- <div class="our wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>CORE TECHNICAL</span>
        </div>
        <h3 class="pp-subtitle">关键专利及学术成果</h3>
        <div class="our-content container-fluid">
          <div class="our-item">
            <img src="../../assets/img/ouritem1.png" alt="" />
            <div class="our-service">
              <span class="our-title"> OUR SERVICE </span>
              <h3 class="our-subtitle">高纯度脐带血干细...</h3>
              <div class="our-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </div>
          </div>
          <div class="our-item">
            <img src="../../assets/img/ouritem2.png" alt="" />
            <div class="our-service">
              <span class="our-title"> OUR SERVICE </span>
              <h3 class="our-subtitle">脐带血样品稀释液...</h3>
              <div class="our-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </div>
          </div>
          <div class="our-item">
            <img src="../../assets/img/ouritem3.png" alt="" />
            <div class="our-service">
              <span class="our-title"> OUR SERVICE </span>
              <h3 class="our-subtitle">用于体外规模化生...</h3>
              <div class="our-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="home-expert">
      <div class="container-fluid">
        <div class="expert wow animated fadeInUp">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>OUR SERVICE</span>
          </div>
          <div class="expert-mark">
            <div class="mark-left">
              <h3>专业化团队和多方面专家支持</h3>
              <h3>带来的创新与新思路</h3>
            </div>
            <div class="mark-right">
              <p>
                公司拥有资深的技术团队，包括细胞生物学、基础医学和临床医学的博士、硕士，并依托北大医疗产业园及相关专家支持，在细胞治疗等领域具备较
              </p>
              <div class="mark-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </div>
          </div>
          <div class="expert-content">
            <div class="expert-item">
              <img src="../../assets/img/yyx.png" alt="" />
              <div class="presend">
                <div>
                  <h3>尹玉新</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>首席科学家</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/xyz.png" alt="" />
              <div class="presend">
                <div>
                  <h3>奚永志</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>临床医学的博士</span>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/gsp.png" alt="" />
              <div class="presend">
                <div>
                  <h3>高舒平</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i><span>高级顾问</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="person">
            <div class="person-item">
              <img src="../../assets/img/dr.png" alt="" />
              <div>
                <h2>200+</h2>
                <span>博士团队</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/postgraduate.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>研究生</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/researchers.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>研究庄家</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/Clinical.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>临床专家</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="working">
      <div class="container-fluid working-content wow animated fadeInUp">
        <div class="wk-title">
          <img src="../../assets/img/title.png" alt="" />
          <span>WORKING VIDEO</span>
        </div>
        <h3>提供卓越的干细胞制备技术</h3>
        <h3>为临床治疗提供更加安全、有效的治疗方案</h3>
        <img
          src="../../assets/img/mvBtn.png"
          @click="handlePlayVideo"
          class="mvbtn"
          alt=""
        />
      </div>
    </div>

    <el-dialog
      title="北正视频"
      width="80%"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <video
        ref="myVideo"
        class="bz_video"
        controls
        src="../../assets/img/bz.mp4"
      >
        <p>你的浏览器不支持video标签</p>
      </video>
    </el-dialog>

    <!-- <div class="partners">
      <div class="container-fluid partners-content wow animated fadeInUp">
        <div class="partners-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>OUR SERVICE</span>
        </div>
        <h3>部分合作伙伴</h3>
        <p>另有多家医院及体制内医院不便展示</p>
        <div class="hospital-list">
          <div class="hospital">
            <img src="../../assets/img/partner1.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>中日友好医院</span>
              </dd>
            </dl>
            <div class="hospital-mores">
              <span>了解更多</span>
              <i class="el-icon-right iconRight"></i>
            </div>
          </div>
          <div class="hospital">
            <img src="../../assets/img/partner2.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>北京天坛医院</span>
              </dd>
            </dl>
           
          </div>
          <div class="hospital">
            <img src="../../assets/img/partner3.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>北京地坛医院</span>
              </dd>
            </dl>
            
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="plan">
      <div class="container-fluid wow animated fadeInUp">
        <div class="plan-content">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>OUR SERVICE</span>
          </div>
          <div class="expert-mark">
            <div class="mark-left">
              <h3>干细胞技术产品</h3>
              <h3>和临床应用方案</h3>
            </div>
            <div class="mark-right">
              <p>
                公司拥有资深的技术团队，包括细胞生物学、基础医学和临床医学的博士、硕士，并依托北大医疗产业园及相关专家支持，在细胞治疗等领域具备较高的技术实力。
              </p>
            </div>
          </div>
          <div class="plan-list">
            <div class="plan-item">
              <img src="../../assets/img/plan1.png" alt="" />
              <p>细胞创新药研发</p>
            </div>
            <div class="plan-item tri-bottom">
              <img src="../../assets/img/plan2.png" alt="" />
              <p>细胞技术研究</p>
            </div>
            <div class="plan-item">
              <img src="../../assets/img/plan3.png" alt="" />
              <p>细胞存储服务</p>
            </div>
            <div class="plan-item">
              <img src="../../assets/img/plan4.png" alt="" />
              <p>抗衰及医美产品开发</p>
            </div>
          </div>
          <dl class="plan-detail">
            <dt>
              <img src="../../assets/img/planxb.png" alt="" />
            </dt>
            <dd>
              <h4>02 细胞技术研究</h4>
              <h6>Our Service</h6>
              <p>
                深入研究细胞的特性和功能，探索细胞在健康和疾病中的作用，为产业发展和药物研发提供技术支持。
              </p>
              <div class="plan-more">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div> -->

    <div class="trends">
      <div class="container-fluid wow animated fadeInUp">
        <div class="trends-content">
          <div class="partners-title">
            <img src="../../assets/img/bm.png" alt="" />
            <span>OUR SERVICE</span>
          </div>
          <h3>最新动态</h3>
          <div class="trends-list">
            <dl>
              <dt>
                <img src="../../assets/img/trends1.png" alt="" />
              </dt>
              <dd>
                <p>干细胞创新药申报成功</p>
                <span>2022.08.30</span>
                <!-- <div class="trends-more">
                  <span>了解更多</span>
                  <i class="el-icon-right iconRight"></i>
                </div> -->
              </dd>
            </dl>
            <dl>
              <dt>
                <img src="../../assets/img/trends2.jpg" alt="" />
              </dt>
              <dd>
                <p>与首都医科大学附属北京同仁医院合作进行临床前研究项目</p>
                <span>2023.05.29</span>
                <!-- <div class="trends-more">
                  <span>了解更多</span>
                  <i class="el-icon-right iconRight"></i>
                </div> -->
              </dd>
            </dl>
            <dl>
              <dt>
                <img src="../../assets/img/trends.png" alt="" />
              </dt>
              <dd>
                <p>
                  我司代表赣州市参加“中国（南昌）国际大健康产业大会暨博览会”
                </p>
                <span>2023.06.29</span>
                <!-- <div class="trends-more">
                  <span>了解更多</span>
                  <i class="el-icon-right iconRight"></i>
                </div> -->
              </dd>
            </dl>
          </div>
          <!-- <div class="partners-title">
            <img src="../../assets/img/bm.png" alt="" />
            <span>TESTIMONIAL</span>
          </div> -->
          <!-- <h3>客户评价</h3>
          <div class="tes">
            <div class="tes-item">
              <p>
                "选择北正赛欧，选择高品质干细胞治疗。资深技术团队与秉承创新的思路，为广大患者提供更有效、更安全的治疗方案。"
              </p>
              <dl>
                <dt>
                  <img src="../../assets/img/male.png" alt="" />
                </dt>
                <dd>
                  <span>戴永明/集团Ceo&Founder</span>
                  <div class="tiny">
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                  </div>
                </dd>
              </dl>
            </div>
            <div class="tes-item">
              <p>
                公司拥有独立的技术优势，包括细胞制备技术超前、细胞产出效率高、无需配型等，使得公司制备的细胞纯度高、活性高、通用性好。
              </p>
              <dl>
                <dt>
                  <img src="../../assets/img/girl.png" alt="" />
                </dt>
                <dd>
                  <span>戴永明/集团Ceo&Founder</span>
                  <div class="tiny">
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                    <img src="../../assets/img/tiny.png" alt="" />
                  </div>
                </dd>
              </dl>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <weCode></weCode>
    <!-- <div class="footer-logo">
      <div class="container-fluid">
        <div class="school wow animated fadeInUp">
          <img src="../../assets/img/school1.png" alt="" />
          <img src="../../assets/img/school2.png" alt="" />
          <img src="../../assets/img/school3.png" alt="" />
          <img src="../../assets/img/school2.png" alt="" />
        </div>
      </div>
    </div> -->

    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: { Navbar, Footer },
  data() {
    return {
      dialogVisible: false,
      videoActive: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    handleToDetail() {
      this.$router.push("/detail");
    },
    handlePlayVideo() {
      this.dialogVisible = true;
      if (this.videoActive) {
        this.$refs.myVideo.play();
      } else {
        this.videoActive = true;
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.$refs.myVideo.pause();
    },
  },
};
</script>
<style scoped>
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.content {
  height: 870px;
  background: #f0f2ef;
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 330px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.offcanvas-end button {
  font-size: 14px;
  font-weight: 600;
}

.btn-down1 {
  border-radius: 200px;
  font-size: 15px;
}

/* .content */
.gene {
  height: 100%;
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gene_right {
  margin-left: 40px;
}

.gene_img {
  width: 430px;
  height: 570px;
  position: relative;
  background: url(../../assets/img/gene.png) no-repeat;
  box-shadow: -5px 5px 10px -4px #dee4e7, 5px 5px 10px -4px #dee4e7;
}

.gene_detail {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 130px;
  left: -200px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 5px 2px #dee4e7;
}

.service {
  width: 70px;
  height: 65px;
  margin-top: 65px;
  margin-bottom: 30px;
}

.gene_detail h3 {
  font-size: 24px;
  color: #005a7e;
  margin: 0;
}

.gene_detail .learn-mores {
  width: 160px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b4b4b4;
  cursor: pointer;
}

.gene_detail .learn-mores span {
  font-size: 14px;
  font-weight: 600;
  color: #015b7e;
  padding-right: 10px;
}

.learn-mores .iconRight {
  font-size: 14px !important;
  /* font-weight: 600; */
  color: #015b7e;
  line-height: 50px;
}

.gene_detail .learn-mores:hover {
  background-color: #1da2ab;
}

.gene_detail .learn-mores:hover span {
  color: #fff;
}

.gene_detail .learn-mores:hover .iconRight {
  color: #fff;
}

.gene-content {
  width: 610px;
  height: 570px;
}

.gene-mv {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.gene-mv img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

/* .gene-mv img:hover{
  content: url(../../assets/img/mvBtn.png);
} */
.gene-mv span {
  font-size: 14px;
  color: #005a7e;
  padding-left: 20px;
  font-weight: bolder;
}

.gene-title {
  display: flex;
  align-items: center;
}

.gene-title img {
  width: 45px;
  height: 5px;
}

.gene-title span {
  font-size: 14px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.gene_right h2 {
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 25px;
}

.gene_right p {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 30px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
}

.gene-purpose {
  margin-left: 70px;
  margin-top: 20px;
}

.gene-purpose h5 {
  font-size: 22px;
  font-weight: bold;
  color: #005a7e;
  margin: 0;
}

.gene-purpose p {
  margin-top: 20px;
}

/* preparation */

.preparation {
  width: 100%;
  height: 710px;
  background: url(../../assets/img/preparation.png) no-repeat;
  background-size: 100% auto;
  background-position: 100% 56%;
  position: relative;
  /* overflow: hidden; */
}

.pp-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.pp-title img {
  width: 45px;
  height: 5px;
}

.pp-title span {
  font-size: 16px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.pp-subtitle {
  font-size: 34px;
  color: #005a7e;
  text-align: center;
  font-weight: bolder;
  margin: 0;
  margin-top: 25px;
}

.pp-mark {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
}

.pp-content {
  display: flex;
  flex-wrap: wrap;
  width: 1170px;
  position: absolute;
  bottom: -420px;
  left: 50%;
  margin-left: -585px;
  box-shadow: 0 0 5px 2px #dee4e7;
  border-spacing: 0;
  border-collapse: collapse;
}

.pp-item {
  width: 390px;
  height: 420px;
  background: #fff;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  padding: 50px 40px;
}

.pp-item img {
  width: 55px;
  height: 55px;
}

.pp-item h4 {
  margin: 0;
  font-size: 22px;
  color: #005a7e;
  font-weight: bolder;
  margin-top: 35px;
}

.pp-item p {
  font-size: 16px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 0;
  height: 120px;
}

.pp-learn-more {
  width: 160px;
  height: 50px;
  margin-top: 25px;
  border-radius: 5px;
  border: 1px solid #b9b9b9;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pp-learn-more span {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
  padding-left: 33px;
  padding-right: 10px;
}

.pp-learn-more .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
}

.pp-item:hover {
  background-color: #1da2ab;
}
.pp-item:hover .stem{
  content: url(../../assets/img/stems.svg);
}
.pp-item:hover .team {
  content: url(../../assets/img/treats.png);
}
.pp-item:hover .thinking {
  content: url(../../assets/img/thinkings.png);
}

.pp-item:hover .lab {
  content: url(../../assets/img/lab.svg);
}
.pp-item:hover .patent {
  content: url(../../assets/img/patents.svg);
}
.pp-item:hover .secure {
  content: url(../../assets/img/secures.svg);
}
/* .pp-item:hover img {
  content: url(../../assets/img/stems.svg);
} */

.pp-item:hover p {
  color: #fff;
}

.pp-item:hover h4 {
  color: #fff;
}

.pp-item:hover .pp-learn-more {
  border-color: #1da2ab;
}

.pp-item:hover .pp-learn-more span {
  color: #fff;
}

.pp-item:hover .pp-learn-more .iconRight {
  color: #fff;
}

/* details */
.bz-detail {
  width: 100%;
  height: 1280px;
  background: #eaefeb;
  overflow: hidden;
}

.core {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 570px;
  /* width: 1170px; */
}

.core-left {
  width: 570px;
}

.core-right {
  margin-left: 180px;
}

.core-right img {
  width: 580px;
  height: 580px;
}

.core-title {
  display: flex;
  align-items: center;
  padding-top: 90px;
}

.core-title img {
  width: 45px;
  height: 5px;
}

.core-title span {
  font-size: 16px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.core-subtitle {
  font-size: 34px;
  color: #005a7e;
  text-align: left;
  font-weight: bolder;
  margin: 0;
  margin-top: 25px;
}

.core-mark {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 28px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: left;
}

.our {
  margin-top: 80px;
}

.our-content {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.our-item {
  width: 495px;
  height: 380px;
  position: relative;
  cursor: pointer;
  transition: width 0.3s ease;
}

.our-item:hover {
  width: 580px;
}

.our-item img {
  width: 100%;
  height: 100%;
}

.our-service {
  width: 280px;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  bottom: -50px;
  left: 30px;
  padding: 20px 30px;
}

.our-service .our-title {
  font-size: 12px;
  color: #085292;
  font-weight: bold;
  line-height: 20px;
}

.our-service .our-subtitle {
  font-size: 22px;
  color: #005a7e;
  font-weight: bolder;
  margin: 0;
  margin-top: 10px;
}

.our-item:hover .our-service {
  height: 175px;
}

.our-item:hover .our-service .our-mores {
  display: flex;
}

.our-mores {
  width: 160px;
  height: 50px;
  border-radius: 5px;
  background-color: #1e929a;
  color: #fff;
  display: none;
  align-items: center;
  margin-top: 20px;
}

.our-mores span {
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 40px;
}

/* expert */
.home-expert {
  width: 100%;
  height: 1200px;
  background-color: #fefdff;
  overflow: hidden;
}

.expert {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
}

.expert-mark {
  display: flex;
  justify-content: space-between;
}

.mark-left h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  margin-top: 25px;
  font-weight: bold;
}

.mark-right {
  width: 560px;
}

.mark-right p {
  margin: 0;
  margin-top: 25px;
  line-height: 28px;
  font-size: 16px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
}

.mark-mores {
  margin-top: 25px;
}

.mark-mores span {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
  padding-right: 30px;
}

.mark-mores .iconRight {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
}

.expert-content {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.expert-item {
  width: 370px;
  height: 430px;
  position: relative;
}

.expert-item img {
  width: 100%;
  height: 100%;
}

.presend {
  width: 330px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  bottom: -45px;
  left: 20px;
  border-radius: 5px;
  box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.1),
    100px 100px 80px rgba(0, 0, 0, 0.05);
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
}

.presend h3 {
  font-size: 20px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.presend .iconRight {
  color: #5f80c8;
  font-size: 18px;
  font-weight: 600;
}

.presend .lable {
  display: flex;
  align-items: center;
}

.presend span {
  font-size: 14px;
  color: #747474;
  font-weight: 600;
  padding-left: 6px;
}

.expertRight {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.expertRight:hover {
  content: url(../../assets/img/expertRights.png);
}

.person {
  width: 100%;
  height: 235px;
  margin-top: 125px;
  border-radius: 5px;
  box-shadow: -5px 5px 10px 5px #ededf9, 5px 5px 10px 5px #ededf9;
  display: flex;
  justify-content: space-between;
  padding: 80px;
}

.person-item {
  display: flex;
}

.person-item h2 {
  font-size: 26px;
  font-weight: bolder;
  color: #005a7e;
  padding-left: 15px;
  margin: 0;
  margin-bottom: 15px;
}

.person-item span {
  font-size: 16px;
  color: #747474;
  padding-left: 15px;
  margin: 0;
}

.working {
  width: 100%;
  height: 500px;
  background: url(../../assets/img/working.png) no-repeat;
  background-size: 100% 100%;
}
.bz_video {
  width: 100%;
  height: 60%;
  object-fit: fill;
}

.wk-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
}

.wk-title img {
  width: 45px;
  height: 5px;
}

.working-content {
  text-align: center;
}

.wk-title span {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding-left: 10px;
}

.working-content h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin-top: 25px;
}

.mvbtn {
  width: 110px;
  height: 110px;
  margin: 30px auto;
  cursor: pointer;
}

.wk-more {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.partners {
  width: 100%;
  height: 870px;
  background-color: #fefdff;
}

.partners-content {
  text-align: center;
}

.partners-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
}

.partners-title img {
  width: 45px;
  height: 5px;
}

.partners-title span {
  font-size: 16px;
  color: #005a7e;
  font-weight: 600;
  padding-left: 10px;
}

.partners-content h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin-top: 25px;
}

.partners-content p {
  margin: 0;
  font-size: 16px;
  color: #747474;
  font-weight: bold;
  margin-top: 20px;
  letter-spacing: 2px;
}

.hospital-list {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.hospital {
  width: 370px;
  height: 400px;
  box-shadow: 0 0 5px 2px #eaeaf8;
  cursor: pointer;
}

.hospital:nth-child(2) {
  margin-left: 35px;
  margin-right: 35px;
}

.hospital img {
  width: 370px;
  height: 270px;
}

.hospital dl {
  display: flex;
  padding: 30px;
  height: 105px;
  margin: 0;
}

.hospital dl dt img {
  width: 60px;
  height: 50px;
}

.hospital dl dd {
  text-align: left;
  margin-left: 15px;
}

.hospital dl dd h6 {
  margin: 0;
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
  padding-bottom: 5px;
}

.hospital dl dd span {
  font-size: 16px;
  font-weight: 600;
}

.hospital-mores {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
}

.hospital-mores span {
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
  padding-left: 105px;
  padding-right: 40px;
}

.hospital-mores .iconRight {
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
}

.hospital:hover .hospital-mores {
  background-color: #1e929a;
}

.hospital:hover .hospital-mores span {
  color: #fff;
}

.hospital:hover .hospital-mores .iconRight {
  color: #fff;
}

.plan {
  width: 100%;
  height: 965px;
  background: url(../../assets/img/workings.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.plan-content {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
}

.plan-list {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.plan-item {
  width: 270px;
  height: 110px;
  /* border: 1px solid #717c7f; */
  box-shadow: 0 0 5px 1px #717c7f;
  padding: 30px;
  display: flex;
}

.tri-bottom {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #a5c8db;
}

.tri-bottom::before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 20px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  top: 110px;
  left: 115px;
}

.plan-item img {
  width: 60px;
  height: auto;
}

.plan-item p {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  line-height: 26px;
}

.plan-detail {
  margin: 60px;
  height: 390px;
  /* background-color: #005a7e; */
  display: flex;
}

.plan-detail dt {
  border-radius: 8px;
  overflow: hidden;
  width: 475px;
  height: 390px;
}

.plan-detail dd {
  margin-left: 65px;
  flex: 1;
  height: 100%;
}

.plan-detail dd h4 {
  /* margin: 0; */
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.plan-detail dd h6 {
  margin: 0;
  font-size: 16px;
  color: #085292;
  font-weight: 600;
}

.plan-detail dd p {
  margin: 0;
  font-size: 16px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 36px;
}

.plan-detail dt img {
  width: 100%;
  height: auto;
}

.plan-more {
  display: flex;
  align-items: center;
  margin-top: 130px;
  cursor: pointer;
}

.plan-more span {
  font-size: 16px;
  color: #085292;
  font-weight: 600;
  padding-right: 40px;
}

.plan-more .iconRight {
  font-size: 16px;
  color: #085292;
  font-weight: 600;
}

.trends {
  width: 100%;
  height: 900px;
  background-color: #fff;
  overflow: hidden;
}

.trends-content {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
  /* background: url(../../assets/img/map.png) no-repeat; */
  background-size: 1060px auto;
  background-position: 50% 640px;
}

.trends-content h3 {
  margin: 0;
  font-size: 34px;
  color: #000;
  font-weight: bold;
  margin-top: 22px;
  text-align: center;
  letter-spacing: 2px;
}

.trends-list {
  display: flex;
  justify-content: center;
}

.trends-list dl {
  width: 370px;
  height: 440px;
  margin: 0;
  margin-top: 60px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #a5c8db;
  cursor: pointer;
  transition: height 0.2s ease;
}

.trends-list dl:nth-child(2) {
  margin-left: 30px;
  margin-right: 30px;
}

.trends-list dl dd {
  padding: 30px;
  margin: 0;
}

.trends-list dl dd p {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 20px;
}

.trends-list dl dd span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}
.trends-list dl dt{
  width: 100%;
  height: 285px;
  overflow: hidden;
}

.trends-list dl dt img {
  width: 100%;
  height: 285px;
}

.trends-more {
  width: 160px;
  height: 50px;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: #1e929a;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
}

/* .trends-list dl:hover {
  height: 500px;
} */

.trends-list dl:hover .trends-more {
  display: flex;
}

.trends-more .iconRight {
  font-size: 14px;
  font-weight: 600;
}

.tes {
  display: flex;
  justify-content: center;
}

.tes-item {
  width: 570px;
  height: 260px;
  box-shadow: 0 0 2px 1px #a5c8db;
  margin-top: 80px;
  padding: 45px;
  background-color: #fff;
}

.tes-item p {
  font-size: 16px;
  color: #747474;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0;
  height: 110px;
}

.tes-item:first-child {
  margin-right: 15px;
}

.tes-item:last-child {
  margin-left: 15px;
}

.tes-item dl {
  display: flex;
  align-items: center;
  margin: 0;
}

.tes-item dl dt img {
  width: 60px;
  height: 60px;
}

.tes-item dl dd {
  /* padding: 30px; */
  margin: 0;
  margin-left: 30px;
}

.tes-item dl dd span {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  color: #000;
}

.tiny {
  padding-top: 10px;
}

.footer-logo {
  width: 100%;
  height: 240px;
  background-color: #b3d7e1;
}

.school {
  padding-left: 10%;
  padding-right: 10%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.school img {
  width: 230px;
  height: auto;
}

/* ---------footer----- */
.footer {
  background-color: #105f7d;
}

.footer-container {
  padding-left: 10%;
  padding-right: 10%;
}

.footer-main {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 40px;
  margin: 0 !important;
}

.footer-main h5 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  padding-bottom: 20px;
}

.footerLogo {
  width: 150px;
  height: auto;
  margin-bottom: 25px;
}

.footer-main p {
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  font-weight: bolder;
  color: #fff;
  letter-spacing: 2px;
}

.footer-main p img {
  width: 30px;
  height: auto;
  margin-right: 8px;
}

.footer-main a {
  text-decoration: none;
  line-height: 40px;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 2px;
}

.text-footer {
  padding: 0 !important;
}

.text-footer:first-child {
  padding-right: 70px !important;
}

.list-unstyled {
  margin-bottom: 0;
}

.footer-copy {
  line-height: 85px;
  width: 100%;
  border-top: 2px solid #95bfcd;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.footer-more {
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.footer-more span {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.footer-more .iconRight {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
::v-deep .el-dialog {
  margin-top: 0 !important;
}
::v-deep .el-dialog__body {
  padding: 0;
}
</style>