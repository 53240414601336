import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import { WOW } from 'wowjs'
import 'wowjs/css/libs/animate.css'

import '@/utils/reset.css'

// import animated from "animate.css";
// Vue.use(animated)

import 'postcss-preset-env'

import WeCode from '@/components/weCode'
//全局组件 微信二维码
Vue.component('weCode', WeCode)

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'

Vue.use(BootstrapVue)

// px2rem 自适应
import 'lib-flexible'
import '@/utils/flexible'

// 滚动动画 wow.js
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 150, // default
  mobile: true, // default
  live: false,
})

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
