<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>

    <!-- header -->
    <Navbar></Navbar>

    <div class="banner">
      <img class="banner-img" src="../../assets/img/aboutUsBanner.png" alt="" />
      <div class="head-card">
        <h3>创新引领 医疗先行</h3>
        <h6>
          我们是一家以技术创新为核心的生物科技企业，致力于为生命科学研究和医学应用提供领先的解决方案！
        </h6>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid company animated fadeInUp">
        <div class="company_left">
          <div class="gene-content">
            <div class="gene-title">
              <img src="../../assets/img/bm.png" />
              <span>FOUNDER INTRODUCTION</span>
            </div>
            <h2>走进北正</h2>
            <p>
              北正生物起源于2008年,总部位于北京中关村生命科技园核心区-北大医疗产业园。公司汇集了北大基础医学院、北大生命科学学院、北大干细胞研究中心和军事科学院等相关机构技术和专家团队,专注于干细胞、免疫细胞技术创新。公司已取得国内外多项干细胞、免疫细胞技术发明专利。
            </p>
            <p>
              公司现有北京总部基地和赣南区域细胞制备中心两个分中心,分别负责细胞技术和创新药物研发、干细胞种子资源保藏工作,建立了涵盖细胞产业上中下游的全产业布局。
            </p>
            <p>
              目前，公司正积极摊进细胞创新药物研究与临床申报工作,结合细胞产业发展趋势和细胞药物生产特点,是全球第一家全面采用3D细胞培养工艺流程的细胞药物创新企业,并以此为基础进行干细胞药物研发创新及药物申报。通过3D自动化生物反应器和细胞收获系统的应用,全面替代传统2D干细胞培养模式，建立了3D干细胞培养工艺、质量检验、分级建库、药学研究等全套标准,实现干细胞生产的定制化、智能化、自动化和规模化。
            </p>
          </div>
        </div>
        <div class="company_right">
          <img class="bzImg" src="../../assets/img/bzaddress.png" alt="" />
        </div>
      </div>
      <div class="container-fluid gene wow animated fadeInUp">
        <div class="gene_left">
          <div class="gene_img"></div>
        </div>
        <div class="gene_right">
          <div class="gene-content">
            <div class="gene-title">
              <img src="../../assets/img/bm.png" />
              <span>FOUNDER INTRODUCTION</span>
            </div>
            <h2>创始人介绍</h2>
            <p>
              20多年从事康复医学临床及医疗管理工作经验，擅长进行医疗机构整体运营及管理，在京沪赣等地区有丰富的人脉资源。
            </p>
            <p>
              早在2003一2004年干细胞研究进入我国的初期，就已开始涉足这一领域至今约20年。并在2008年北京奥运会及残奥会，获颁“医疗卫生保障突出贡献荣誉证书”。
            </p>
            <p>
              北正作为一家致力于前沿生物科研与应用的企业，我们的使命是为人类的健康和幸福作出贡献。
            </p>
            <p class="zy">
              北正创始人：<img src="../../assets/img/zy.png" alt="" />
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid company wow animated fadeInUp">
        <div class="company_left">
          <div class="gene-content">
            <div class="gene-title">
              <img src="../../assets/img/bm.png" />
              <span>FOUNDER INTRODUCTION</span>
            </div>
            <h2>京赣产业联动</h2>
            <p>
              依托北京公司优质资源，支持革命苏区生物产业。北正旗下江西人体组织样本库是江西省第一家专业干细胞制备中心及人体组织样本存储库。
            </p>
            <p>
              公司位于江西省赣州市经济技术开发区国际企业中心。以“关注生命,提高生命质量”为宗旨。坚持“诚信、专业、先进、发展”的企业文化。掌握美国、中国多项专利技术,拥有20项国家保护专利。
            </p>
            <p>
              江西人体组织样本库以“组织细胞库+区域细胞制备中心+细胞质量云平台”(1库+1中心+1平台)产业科研发展新模式。推动江西省人体组织样本资源的收集及应用,为革命苏区生物产业发展和人民的生命健康做出应有贡献。
            </p>
            <p>
              北正以江西公司作为未来上市主体,江西公司依托北京总部相关资源,在当地建立细胞全产业链的高标准平台。
            </p>
            <h6 class="t5">江西人体组织样本库(全国第一家组织样本库)</h6>
            <h6>赣南区域细胞制备中心(卫健委官方)</h6>
            <h6>赣州细胞质量检验中心(质量中心)</h6>
            <h6>赣州细胞与组织工程中心(技术研发中心)</h6>
          </div>
        </div>
        <div class="company_right">
          <img src="../../assets/img/jiangxi.png" alt="" />
        </div>
      </div>

      <div class="container-fluid qualifications wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>GROUP QUALIFICATION</span>
        </div>
        <h3 class="pp-subtitle">荣誉资质</h3>
        <div class="certificate-list">
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (2).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (3).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (5).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (1).jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="certificate-list">
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (3).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (1).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (2).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (4).jpg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div id="contUs" class="contactUs wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>CONTACT US</span>
        </div>
        <h3 class="pp-subtitle">联系我们</h3>
        <div class="bzMap">
          <img src="../../assets/img/bz-map.png" alt="">
        </div>
        <!-- <div class="contact"></div> -->
        <div class="gene">
          <div class="gene_right">
            <div class="gene-content">
              <h2>北正赛欧(北京)生物科技有限公司</h2>
              <p>
                <img src="../../assets/img/phones.png" alt="" />
                8610-80765233
              </p>
              <p>
                <img src="../../assets/img/addresss.png" alt="" />
                北京市昌平区生命园中路8号一区10号楼4层
              </p>
              <p>
                <img src="../../assets/img/emails.png" alt="" />
                beizhengsc@bz-sc.com
              </p>
              <dl class="official">
                <dt><img src="../../assets/img/bjgzh.png" alt=""></dt>
                <dd>北正干细胞</dd>
              </dl>
            </div>
          </div>
          <div class="gene_right">
            <div class="gene-content">
              <h2>江西北正干细胞生物科技有限公司</h2>
              <p>
                <img src="../../assets/img/phones.png" alt="" />
                0797-8370337
              </p>
              <p>
                <img src="../../assets/img/addresss.png" alt="" />
                江西省赣州市赣州经济技术开发区香江大道北侧、华坚北路西侧赣州国际企业中心5A号楼101室
              </p>
              <p>
                <img src="../../assets/img/emails.png" alt="" />
                beizhengsc@bz-sc.com
              </p>
              <dl class="official">
                <dt><img src="../../assets/img/jxgzh.png" alt=""></dt>
                <dd>赣南区域细胞制备中心</dd>
              </dl>
              
            </div>
          </div>
        </div>

      </div>
      
    </div>

    <weCode></weCode>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/headers";
import Footer from "@/components/footer";
export default {
  components: { Navbar, Footer },
  data() {
    return {};
  },
  created() {
    
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
    var usid = this.$route.query.id;
    if(usid){
      console.log(usid)
      var anchor = document.getElementById(usid);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      // window.pageYOffset = anchor.offsetTop;
    }
  },
};
</script>
<style scoped>
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 385px;
  position: relative;
}
.banner-img {
  width: 100%;
  height: 100%;
}
.head-card {
  width: 100%;
  height: 150px;
  position: absolute;
  background: url(../../assets/img/banner-bg.png) no-repeat;
  top: 125px;
  left: 0;
}
.head-card h3 {
  margin: 0;
  font-size: 54px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
  padding-top: 20px;
  padding-left: 355px;
}
.head-card h6 {
  font-size: 24px;
  color: #fff;
  padding-top: 15px;
  padding-left: 355px;
}
.company {
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}
.company_left {
  width: 610px;
  margin-right: 60px;
}
.company_left h2 {
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}
.official {
  width: 180px;
  margin-top: 60px;
  padding-bottom: 40px;
  text-align: center;
}
.official dt img{
  width: 150px;
  height: 150px;
}
.official dd{
  font-size: 16px;
  color: #4e4e4e;
  letter-spacing: 1px;
  font-weight: 600;
}


.company_left h6 {
  font-size: 18px;
  color: #005a7e;
  font-weight: bold;
  line-height: 38px;
  margin: 0;
}
.t5{
  margin-top: 50px !important;
}
.company_left h3 {
  font-size: 22px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 35px;
}

.company_left p {
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 30px;
  line-height: 20px;
  color: #005a7e;
  font-weight: 600;
  letter-spacing: 1px;
}
.company_right {
  width: 550px;
  margin-left: 60px;
}
.bzImg{
  width: 550px;
  height: 285px;
  margin-top: 80px;
}
.jxImg{
  width: 550px;
  height: 600px;
}
.certificate-list {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.certificate-list-item {
  width: 285px;
  height: 355px;
  /* margin-top: 20px; */
  margin-left: 10px;
  margin-right: 10px;
  background: #fff;
  /* box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5; */
}
.certificate-list-item-img {
  width: 100%;
  height: 270px;
  /* border: 1px solid #999; */
}
.certificate-list-item-imgs {
  width: 100%;
  height: 100%;
  border: 1px solid #999;
}
.certificate-list-item-imgs img {
  width: 100%;
  height: 100%;
  box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5;
}
.certificate-list-item-img img {
  width: 100%;
  height: 170px;
  border: 1px solid #999;
  margin-top: 50px;
  box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5;
}
.gene {
  /* height: 100%; */
  padding-top: 80px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gene_right {
  margin-left: 70px;
}
.gene_img {
  width: 658px;
  height: 440px;
  border-radius: 20px;
  position: relative;
  background: url(../../assets/img/zhaoyong.png) no-repeat;
  box-shadow: -5px 5px 10px -4px #dee4e7, 5px 5px 10px -4px #dee4e7;
  background-size: 100% 100%;
}
.gene_imgs {
  width: 658px;
  height: 440px;
  border-radius: 20px;
  position: relative;
  background: url(../../assets/img/bzMap.png) no-repeat;
  box-shadow: -5px 5px 10px -4px #dee4e7, 5px 5px 10px -4px #dee4e7;
  background-size: 100% 100%;
}
.bzMap{
  width: 100%;
  height: 400px;
}
.bzMap img{
  width: 100%;
  height: 100%;
  /* height: 400px; */
}

.gene-content {
  width: 610px;
  height: 440px;
}

.gene-title {
  display: flex;
  align-items: center;
}

.gene-title img {
  width: 45px;
  height: 5px;
}

.gene-title span {
  font-size: 14px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}

.gene_right h2 {
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.gene_right p {
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 20px;
  color: #005a7e;
  font-weight: 600;
  letter-spacing: 1px;
  
}
.gene_right p img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.zy {
  padding-top: 50px !important;
}
.zy img {
  width: 60px !important;
  height: auto;
}

/* expert */
.home-expert {
  width: 100%;
  height: 1200px;
  background-color: #fefdff;
  overflow: hidden;
}

.expert {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
}

.expert-mark {
  display: flex;
  justify-content: space-between;
}

.mark-left h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  margin-top: 25px;
  font-weight: bold;
}

.mark-right {
  width: 560px;
}

.mark-right p {
  margin: 0;
  margin-top: 25px;
  line-height: 28px;
  font-size: 16px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
}

.mark-mores {
  margin-top: 25px;
}

.mark-mores span {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
  padding-right: 30px;
}

.mark-mores .iconRight {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
}

.expert-content {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.expert-item {
  width: 370px;
  height: 430px;
  position: relative;
}

.expert-item img {
  width: 100%;
  height: 100%;
}

.presend {
  width: 330px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  bottom: -45px;
  left: 20px;
  border-radius: 5px;
  box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.1),
    100px 100px 80px rgba(0, 0, 0, 0.05);
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
}

.presend h3 {
  font-size: 20px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.presend .iconRight {
  color: #5f80c8;
  font-size: 18px;
  font-weight: 600;
}

.presend .lable {
  display: flex;
  align-items: center;
}

.presend span {
  font-size: 14px;
  color: #747474;
  font-weight: 600;
  padding-left: 6px;
}

.expertRight {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.expertRight:hover {
  content: url(../../assets/img/expertRights.png);
}

.person {
  width: 100%;
  height: 235px;
  margin-top: 125px;
  border-radius: 5px;
  box-shadow: -5px 5px 10px 5px #ededf9, 5px 5px 10px 5px #ededf9;
  display: flex;
  justify-content: space-between;
  padding: 80px;
}

.person-item {
  display: flex;
}

.person-item h2 {
  font-size: 26px;
  font-weight: bolder;
  color: #005a7e;
  padding-left: 15px;
  margin: 0;
  margin-bottom: 15px;
}

.person-item span {
  font-size: 16px;
  color: #747474;
  padding-left: 15px;
  margin: 0;
}
</style>