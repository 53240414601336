<template>
  <div class="content">
    <div class="container-fluid animated fadeInUp">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/stemCellApplication/index' }"
            >细胞应用</el-breadcrumb-item
          >
          <el-breadcrumb-item
            :to="{
              path:
                '/stemCellApplication/articleList?title=' +
                title +
                '&category_id=' +
                category_id,
            }"
            >{{ title }}</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ articleData.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="articleContent">
        <div class="article_title">
          <span>{{ articleData.title }}</span>
        </div>
        <div class="article_time">
          <span>发布时间：{{ timestampToTime(articleData.created_at) }}</span>
        </div>
        <div class="article_content">
          <span>{{ articleData.content }}</span>
        </div>
        <div class="article_img">
          <img :src="$store.state.imgURL + articleData.img_url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { GetArticleDetail } from "@/api/index";
export default {
  data() {
    return {
      title: "",
      category_id: "",
      article_id: "",
      articleData: {},
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.category_id = this.$route.query.category_id;
    this.article_id = this.$route.query.article_id;
    this.getData();
  },
  methods: {
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
    getData() {
      let params = {
        article_id: this.article_id,
      };
      GetArticleDetail(params).then((res) => {
        console.log(res);
        this.articleData = res.data.data;
      });
    },
  },
};
</script>
  
<style scoped>
.breadcrumb {
  width: 1190px;
  margin: 70px auto;
}
.articleContent {
  width: 1190px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.article_title {
  font-size: 24px;
  color: #005a7e;
  font-weight: bolder;
  text-align: center;
  line-height: 50px;
}
.article_time {
  font-size: 14px;
  color: #aaaaaa;
  text-align: center;
  margin-top: 20px;
}
.article_content {
  margin-top: 45px;
}
.article_content span {
  font-size: 12px;
  color: #005a7e;
  line-height: 26px;
}
.article_img {
  width: 100%;
  height: 500px;
  margin-top: 40px;
  text-align: center;
}
.article_img img {
  height: 500px;
}
</style>