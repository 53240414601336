<template>
  <div class="content">
    <div class="container-fluid animated fadeInUp">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/stemCellApplication/index' }"
            >细胞应用</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="item_list">
        <div class="item" v-for="(item, index) in articleData" :key="index">
          <div class="item_img">
            <img :src="$store.state.imgURL + item.img_url" alt="" />
          </div>
          <div class="item_title">
            <span>{{ item.title }}</span>
          </div>
          <div class="item_time">
            <span>{{ item.cn_create_at }}</span>
          </div>
          <div class="item_content">
            <span>{{ item.description }}</span>
          </div>
          <div class="item_btn" @click="handleToContent(item)">
            <span>阅读内容</span>
            <i class="el-icon-right iconRight"></i>
          </div>
        </div>
      </div>
      <div class="item_page">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetArticleList } from "@/api/index";
export default {
  data() {
    return {
      title: "",
      category_id: "",
      articleData: [],
      total: 0,
      page: 1,
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.category_id = this.$route.query.category_id;
    this.getData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      let params = {
        category_id: this.category_id,
        page: 1,
        per_page: 9,
      };
      GetArticleList(params).then((res) => {
        console.log(res);
        this.total = res.data.data.total;
        this.articleData = res.data.data.data;
      });
    },
    handleToContent(item) {
      this.$router.push({
        path: "/stemCellApplication/articleContent",
        query: {
          article_id: item.article_id,
          title: this.title,
          category_id: this.category_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  width: 1190px;
  margin: 70px auto;
}
.item_list {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.item {
  width: 370px;
  /* height: 515px; */
  /* background: skyblue; */
  margin-bottom: 55px;
}
.item_img {
  width: 100%;
  height: 240px;
}
.item_img img {
  width: 100%;
  height: 100%;
}
.item_title {
  margin-top: 40px;
  margin-bottom: 20px;
}
.item_title span {
  font-size: 22px;
  color: #005a7e;
  font-weight: bolder;
}
.item_time {
  font-size: 12px;
  color: #aaaaaa;
}
.item_content {
  margin-top: 15px;
}
.item_content span {
  font-size: 12px;
  color: #005a7e;
  line-height: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.item_btn {
  margin-top: 25px;
  width: 125px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  background: #1d8085;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.item_btn span {
  font-size: 12px;
  margin-right: 30px;
}
.item_page {
  margin: 40px auto;
  text-align: center;
}
</style>