<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>

    <!-- header -->
    <Navbar></Navbar>

    <div class="banner">
      <img
        class="banner-img"
        src="../../assets/img/finance-banner.png"
        alt=""
      />
      <div class="head-card">
        <dl>
          <dt>
            <img src="../../assets/img/finance.png" alt="" />
          </dt>
          <dd>
            <h3>解锁未来的财富之源</h3>
            <h4>选择细胞银行，开启金融投资新篇章</h4>
          </dd>
        </dl>
        <!-- <div class="learn-more">
          <span>了解更多</span>
          <i class="el-icon-right iconRight"></i>
        </div> -->
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid finance wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>INTRODUCTION TO CELL BANKING</span>
        </div>
        <h3 class="pp-subtitle">保值增值 为未来投资</h3>
        <div class="finance-list">
          <div class="finance-list-item">
            <img
              class="financeImg"
              src="../../assets/img/finance (2).png"
              alt=""
            />
            <img
              class="financeIcon"
              src="../../assets/img/financeIcon (2).png"
              alt=""
            />
            <h3>投资于未来的行业</h3>
            <p>
              干细胞行业被认为是未来医学领域的重要发展方向，具有广阔的应用前景和商业潜力。作为细胞银行，我们致力于为投资者提供参与这个高成长行业的机会，让您的投资与未来的科学和医疗进步紧密相连。
            </p>
          </div>
          <div class="finance-list-item">
            <img
              class="financeImg"
              src="../../assets/img/finance (3).png"
              alt=""
            />
            <img
              class="financeIcon"
              src="../../assets/img/financeIcon (1).png"
              alt=""
            />
            <h3>多样化的投资合作模式</h3>
            <p>
              我们提供灵活多样的投资合作模式，包括股权投资、战略合作、项目合作等。无论您是个人投资者、机构投资者还是行业合作伙伴，我们都将与您合作，共同探索干细胞行业的投资机会和合作潜力。
            </p>
          </div>
          <div class="finance-list-item">
            <img
              class="financeImg"
              src="../../assets/img/finance (1).png"
              alt=""
            />
            <img
              class="financeIcon"
              src="../../assets/img/financeIcon (3).png"
              alt=""
            />
            <h3>行业发展趋势的指引</h3>
            <p>
              我们将为您提供关于干细胞行业发展趋势的专业指引和市场分析，助您作出明智的投资决策。我们的团队将时刻关注行业动态，为您提供最新的行业信息和前沿趋势，以帮助您把握投资机遇。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-info">
      <div class="form-content wow animated fadeInUp">
        <el-input class="form-item" placeholder="姓名"></el-input>
        <el-input class="form-item" placeholder="手机号"></el-input>
        <el-input type="textarea" class="form-textarea" placeholder="信息">
        </el-input>
        <el-button class="form-item">提交</el-button>
      </div>
    </div>

  

    <!-- content -->
    <div class="content">
      <div class="container-fluid consult wow animated fadeInUp">
        <div>
          <h4>提供专业的咨询服务</h4>
          <h4>为您提供个性化的存储方案和建议</h4>
        </div>
        <div class="learn-more" @click="handleTo">
          <span>联系我们</span>
          <i class="el-icon-right iconRight"></i>
        </div>
      </div>
    </div>

    <weCode></weCode>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/headers";
import Footer from "@/components/footer";
export default {
  components: { Navbar, Footer },
  data() {
    return {};
  },
  methods:{
    handleTo(){
      this.$router.push({ path: '/aboutUs' ,query:{id:'contUs'}})
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
};
</script>
<style scoped>
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.finance-list {
  display: flex;
  justify-content: center;
}
.finance-list-item {
  width: 365px;
  /* height: 510px; */
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: -5px 5px 10px 5px #eff0f9, 5px 5px 10px 5px #eff0f9;
  overflow: hidden;
}
.finance-list-item h3 {
  margin: 0;
  font-size: 22px;
  color: #005a7e;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.finance-list-item p {
  width: 295px;
  /* height: 75px; */
  font-size: 12px;
  color: #005a7e;
  margin: 0 auto;
  text-align: left;
  text-indent: 24px;
  line-height: 20px;
  /* overflow: hidden; */
  /* word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}
.financeImg {
  width: 100%;
  height: 290px;
}
.financeIcon {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.banner {
  width: 100%;
  height: 710px;
  position: relative;
}
.head-card {
  width: 1540px;
  height: 150px;
  background-color: #1da2a9;
  position: absolute;
  right: 10px;
  bottom: -58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
}
.head-card dl {
  display: flex;
  margin: 0;
}
.head-card dt img {
  width: 290px;
  height: 150px;
}
.head-card dd {
  margin: 0;
  margin-left: 45px;
}
.head-card dd h3 {
  font-size: 34px;
  color: #fff;
  font-weight: bold;
  margin: 0;
  margin-top: 40px;
  letter-spacing: 2px;
}
.head-card dd h4 {
  font-size: 24px;
  color: #fff;
  margin: 0;
  margin-top: 12px;
  letter-spacing: 2px;
}
.learn-more {
  width: 200px;
  height: 60px;
  margin-right: 255px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 0;
}

.learn-more span {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
  padding-left: 45px;
  padding-right: 30px;
}

.learn-more .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #015b7e;
}

.banner-img {
  width: 100%;
  height: 100%;
}
.finance {
  height: 1140px;
  padding-top: 100px;
  background: #fefdff;
}
.trends {
  width: 100%;
  height: 690px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 40px;
}

.trends-content {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
  background: url(../../assets/img/map.png) no-repeat;
  background-size: 1060px auto;
  background-position: 50% 640px;
}

.tes {
  display: flex;
  justify-content: center;
}

.tes-item {
  width: 570px;
  height: 260px;
  margin-top: 80px;
  padding: 45px;
  background-color: #fff;
  box-shadow: -5px 5px 10px 5px #eef0f8, 5px 5px 10px 5px #eef0f8;
}

.tes-item p {
  font-size: 16px;
  color: #747474;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0;
  height: 110px;
}

.tes-item:first-child {
  margin-right: 15px;
}

.tes-item:last-child {
  margin-left: 15px;
}

.tes-item dl {
  display: flex;
  align-items: center;
  margin: 0;
}

.tes-item dl dt img {
  width: 60px;
  height: 60px;
}

.tes-item dl dd {
  margin: 0;
  margin-left: 30px;
}

.tes-item dl dd span {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  color: #000;
}
.form-info {
  width: 100%;
  height: 500px;
  background: url(../../assets/img/formImg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 100px;
}
.form-content {
  width: 490px;
  height: 510px;
  background: #1da1a9;
  border-radius: 5px;
  position: absolute;
  top: -120px;
  right: 390px;
  padding: 35px;
}
.form-item {
  margin-top: 20px;
  width: 100%;
  height: 50px;
}

.form-textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
}

.form-textarea /deep/ .el-textarea__inner {
  height: 150px !important;
}
</style>