import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home-index.vue'
import DetailView from '../views/aboutUs/index.vue'
import StemCellView from '../views/stemCellStorage/index.vue'
import StemCellApplicationView from '../views/stemCellApplication/index.vue'
import StemCellApplicationViewList from '../views/stemCellApplication/list.vue'
import ResourceView from '../views/resource/index.vue'
import HealthView from '../views/health/index.vue'
import FinanceView from '../views/finance/index.vue'
import ArticleList from '../views/stemCellApplication/articleList.vue'
import ArticleContent from '../views/stemCellApplication/articleContent.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: DetailView
  },
  {
    path: '/stemCellView',
    name: 'stemCellView',
    component: StemCellView
  },
  {
    path: '/stemCellApplication',
    name: 'StemCellApplicationView',
    redirect: '/stemCellApplication/index',
    component: StemCellApplicationView,
    children: [
      {
        path: 'index',
        name: 'index',
        component: StemCellApplicationViewList
      },
      {
        path: 'articleList',
        name: 'ArticleList',
        component: ArticleList
      },
      {
        path: 'articleContent',
        name: 'ArticleContent',
        component: ArticleContent
      }
    ]
  },
  {
    path: '/resource',
    name: 'Resource',
    component: ResourceView
  },
  {
    path: '/health',
    name: 'Health',
    component: HealthView
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinanceView
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 解决跳转到当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
