<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) == true) {
      window.location.href = 'https://h5.beizhengc.com/'
    }
  }
}
</script>

<style></style>
