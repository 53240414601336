var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid animated fadeInUp"},[_c('div',{staticClass:"breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/stemCellApplication/index' }}},[_vm._v("细胞应用")]),_c('el-breadcrumb-item',{attrs:{"to":{
            path:
              '/stemCellApplication/articleList?title=' +
              _vm.title +
              '&category_id=' +
              _vm.category_id,
          }}},[_vm._v(_vm._s(_vm.title))]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.articleData.title))])],1)],1),_c('div',{staticClass:"articleContent"},[_c('div',{staticClass:"article_title"},[_c('span',[_vm._v(_vm._s(_vm.articleData.title))])]),_c('div',{staticClass:"article_time"},[_c('span',[_vm._v("发布时间："+_vm._s(_vm.timestampToTime(_vm.articleData.created_at)))])]),_c('div',{staticClass:"article_content"},[_c('span',[_vm._v(_vm._s(_vm.articleData.content))])]),_c('div',{staticClass:"article_img"},[_c('img',{attrs:{"src":_vm.$store.state.imgURL + _vm.articleData.img_url,"alt":""}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }