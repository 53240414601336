<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>

    <!-- header -->
    <Navbar></Navbar>

    <div class="banner">
      <img class="banner-img" src="../../assets/img/resource-bg.png" alt="" />
      <div class="head-card">
        <h3>汇聚力量 共铸辉煌</h3>
        <h6>
          与国内外优秀伙伴合作，共享干细胞研究的丰硕成果，开创行业的新纪元！
        </h6>
      </div>
    </div>

    <div class="home-expert">
      <div class="container-fluid">
        <div class="expert animated fadeInUp">
          <div class="gene-title">
            <img src="../../assets/img/bm.png" />
            <span>OUR SERVICE</span>
          </div>
          <div class="expert-mark">
            <div class="mark-left">
              <h3>专业化团队和多方面专家支持</h3>
              <h3>带来的创新与新思路</h3>
            </div>
            <div class="mark-right">
              <p>
                公司拥有资深的技术团队，包括细胞生物学、基础医学和临床医学的博士、硕士，并依托北大医疗产业园及相关专家支持，在细胞治疗等领域具备较
              </p>
              <div class="mark-mores">
                <span>了解更多</span>
                <i class="el-icon-right iconRight"></i>
              </div>
            </div>
          </div>
          <div class="expert-content">
            <div class="expert-item">
              <img src="../../assets/img/yyx.png" alt="" />
              <div class="presend">
                <div>
                  <h3>尹玉新</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>首席科学家</span>
                  </div>
                </div>
                <!-- <div class="expertRight">
                  <img src="../../assets/img/expertRight.png" alt="" />
                </div> -->
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/xyz.png" alt="" />
              <div class="presend">
                <div>
                  <h3>奚永志</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>临床医学的博士</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/gsp.png" alt="" />
              <div class="presend">
                <div>
                  <h3>高舒平</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i><span>高级顾问</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="expert-content">
            <div class="expert-item">
              <img src="../../assets/img/mjp.jpg" alt="" />
              <div class="presend">
                <div>
                  <h3>毛建平</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>研发负责人</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/wcs.jpg" alt="" />
              <div class="presend">
                <div>
                  <h3>王长生</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>北京首席专家</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="expert-item">
              <img src="../../assets/img/xhb.jpg" alt="" />
              <div class="presend">
                <div>
                  <h3>辛洪波</h3>
                  <div class="lable">
                    <i class="el-icon-right iconRight"></i
                    ><span>南昌大学医学院院长</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="person">
            <div class="person-item">
              <img src="../../assets/img/dr.png" alt="" />
              <div>
                <h2>200+</h2>
                <span>博士团队</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/postgraduate.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>研究生</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/researchers.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>研究庄家</span>
              </div>
            </div>
            <div class="person-item">
              <img src="../../assets/img/Clinical.png" alt="" />
              <div>
                <h2>500+</h2>
                <span>临床专家</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="content">
      <div class="container-fluid qualifications wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>GROUP QUALIFICATION</span>
        </div>
        <h3 class="pp-subtitle">荣誉资质</h3>
        <div class="certificate-list">
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (2).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (3).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (5).jpg" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-img">
              <img src="../../assets/img/group (1).jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="certificate-list">
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (3).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (1).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (2).png" alt="" />
            </div>
          </div>
          <div class="certificate-list-item">
            <div class="certificate-list-item-imgs">
              <img src="../../assets/img/group (4).jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid qualifications wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>GROUP QUALIFICATION</span>
        </div>
        <h3 class="pp-subtitle">专利证书</h3>
        <div class="quality-list">
          <div class="quality-item">
            <img src="../../assets/img/zl (1).jpg" alt="" />
          </div>
          <div class="quality-item quality-item-margin">
            <img src="../../assets/img/zl (2).jpg" alt="" />
          </div>
          <div class="quality-item">
            <img src="../../assets/img/zl (3).jpg" alt="" />
          </div>
          <div class="quality-item quality-item-margin">
            <img src="../../assets/img/zl (4).jpg" alt="" />
          </div>
          <div class="quality-item">
            <img src="../../assets/img/zl (5).jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid qualifications wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>GMP LABORATORY</span>
        </div>
        <h3 class="pp-subtitle">GMP实验室</h3>

        <el-carousel :interval="4000" type="card" height="400px">
          <el-carousel-item v-for="(item, index) in imgList" :key="index">
            <img
              :src="$store.state.imgURL + item.img_url"
              class="gmp_img"
              alt=""
          /></el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="partners">
      <div class="container-fluid partners-content wow animated fadeInUp">
        <div class="partners-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>OUR SERVICE</span>
        </div>
        <h3>部分合作伙伴</h3>
        <p>另有多家医院及体制内医院不便展示</p>
        <div class="hospital-list">
          <div class="hospital">
            <img src="../../assets/img/partner1.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>中日友好医院</span>
              </dd>
            </dl>
          </div>
          <div class="hospital">
            <img src="../../assets/img/partner2.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>北京天坛医院</span>
              </dd>
            </dl>
          </div>
          <div class="hospital">
            <img src="../../assets/img/partner3.png" alt="" />
            <dl>
              <dt>
                <img src="../../assets/img/hospital.png" alt="" />
              </dt>
              <dd>
                <h6>Partner</h6>
                <span>北京地坛医院</span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <weCode></weCode>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/headers";
import Footer from "@/components/footer";
import { GetBannerList } from "@/api/index";
export default {
  components: { Navbar, Footer },
  data() {
    return {
      imgList: [],
    };
  },
  created() {
    this.getBanner();
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    getBanner() {
      let params = {
        location: 2,
      };
      GetBannerList(params).then((res) => {
        this.imgList = res.data.data.data;
      });
    },
  },
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.gmp_img {
  width: 100% !important;
  height: 100% !important;
}
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 385px;
  position: relative;
}
.banner-img {
  width: 100%;
  height: 100%;
}
.head-card {
  width: 1330px;
  height: 150px;
  position: absolute;
  background: url(../../assets/img/banner-bg.png) no-repeat;
  top: 125px;
  left: 0;
}
.head-card h3 {
  margin: 0;
  font-size: 54px;
  color: #fff;
  font-weight: 600;
  padding-top: 20px;
  letter-spacing: 2px;
  padding-left: 355px;
}
.head-card h6 {
  font-size: 24px;
  color: #fff;
  padding-top: 15px;
  padding-left: 355px;
}
.qualifications {
  background: #fefdff !important;
}
.certificate-list {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.certificate-list-item {
  width: 285px;
  height: 355px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background: #fff;
  /* box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5; */
}
.certificate-list-item-img {
  width: 100%;
  height: 270px;
}
.certificate-list-item-imgs {
  width: 100%;
  height: 100%;
  border: 1px solid #999;
}
.certificate-list-item-imgs img {
  width: 100%;
  height: 100%;
  box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5;
}
.certificate-list-item-img img {
  width: 100%;
  height: 170px;
  border: 1px solid #999;
  margin-top: 50px;
  box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5;
}

.home-expert {
  width: 100%;
  /* height: 1200px; */
  background-color: #fefdff;
  overflow: hidden;
  /* margin-top: 50px; */
}

.expert {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 80px;
}

.expert-mark {
  display: flex;
  justify-content: space-between;
}

.mark-left h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  margin-top: 25px;
  font-weight: bold;
}

.mark-right {
  width: 560px;
}

.mark-right p {
  margin: 0;
  margin-top: 25px;
  line-height: 28px;
  font-size: 16px;
  color: #747474;
  font-weight: 600;
  letter-spacing: 2px;
}

.mark-mores {
  margin-top: 25px;
}

.mark-mores span {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
  padding-right: 30px;
}

.mark-mores .iconRight {
  font-size: 14px;
  font-weight: 600;
  color: #0b5071;
}

.expert-content {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}

.expert-item {
  width: 370px;
  height: 430px;
  position: relative;
}

.expert-item img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.presend {
  width: 330px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  bottom: -45px;
  left: 20px;
  border-radius: 5px;
  box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.1),
    100px 100px 80px rgba(0, 0, 0, 0.05);
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
}

.presend h3 {
  font-size: 20px;
  color: #005a7e;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.presend .iconRight {
  color: #5f80c8;
  font-size: 18px;
  font-weight: 600;
}

.presend .lable {
  display: flex;
  align-items: center;
}

.presend span {
  font-size: 14px;
  color: #747474;
  font-weight: 600;
  padding-left: 6px;
}

.expertRight {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.expertRight:hover {
  content: url(../../assets/img/expertRights.png);
}

.person {
  width: 100%;
  height: 235px;
  margin-top: 125px;
  border-radius: 5px;
  box-shadow: -5px 5px 10px 5px #ededf9, 5px 5px 10px 5px #ededf9;
  display: flex;
  justify-content: space-between;
  padding: 80px;
  margin-bottom: 40px;
}

.person-item {
  display: flex;
}

.person-item h2 {
  font-size: 26px;
  font-weight: bolder;
  color: #005a7e;
  padding-left: 15px;
  margin: 0;
  margin-bottom: 15px;
}

.person-item span {
  font-size: 16px;
  color: #747474;
  padding-left: 15px;
  margin: 0;
}
.gene-title {
  display: flex;
  align-items: center;
}

.gene-title img {
  width: 45px;
  height: 5px;
}

.gene-title span {
  font-size: 14px;
  color: #085292;
  font-weight: bold;
  padding-left: 10px;
}
.partners {
  width: 100%;
  height: 870px;
  background-color: #fefdff;
}

.partners-content {
  text-align: center;
}

.partners-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
}

.partners-title img {
  width: 45px;
  height: 5px;
}

.partners-title span {
  font-size: 16px;
  color: #005a7e;
  font-weight: 600;
  padding-left: 10px;
}

.partners-content h3 {
  margin: 0;
  font-size: 34px;
  color: #005a7e;
  font-weight: bold;
  margin-top: 25px;
}

.partners-content p {
  margin: 0;
  font-size: 16px;
  color: #747474;
  font-weight: bold;
  margin-top: 20px;
  letter-spacing: 2px;
}

.hospital-list {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.hospital {
  width: 370px;
  height: 390px;
  box-shadow: 0 0 5px 2px #eaeaf8;
  cursor: pointer;
}

.hospital:nth-child(2) {
  margin-left: 35px;
  margin-right: 35px;
}

.hospital img {
  width: 370px;
  height: 270px;
}

.hospital dl {
  display: flex;
  padding: 30px;
  height: 105px;
  margin: 0;
}

.hospital dl dt img {
  width: 60px;
  height: 50px;
}

.hospital dl dd {
  text-align: left;
  margin-left: 15px;
}

.hospital dl dd h6 {
  margin: 0;
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
  padding-bottom: 5px;
}

.hospital dl dd span {
  font-size: 16px;
  font-weight: 600;
}

.hospital-mores {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
}

.hospital-mores span {
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
  padding-left: 105px;
  padding-right: 40px;
}

.hospital-mores .iconRight {
  font-size: 14px;
  color: #005a7e;
  font-weight: 600;
}

.hospital:hover .hospital-mores {
  background-color: #1e929a;
}

.hospital:hover .hospital-mores span {
  color: #fff;
}

.hospital:hover .hospital-mores .iconRight {
  color: #fff;
}
.quality-list {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.quality-item-margin {
  margin-left: 50px;
  margin-right: 50px;
}
.quality-item-margins {
  margin-right: 160px;
}
.quality-item {
  width: 225px;
  height: 320px;
  border: 1px solid #999;
  /* background: #ccc; */
  box-shadow: -5px 2px 5px -4px #e7eaf5, 5px 2px 5px -4px #e7eaf5;
}
.quality-item img {
  width: 100%;
  height: 100%;
}
</style>