import { render, staticRenderFns } from "./articleList.vue?vue&type=template&id=19f69a62&scoped=true&"
import script from "./articleList.vue?vue&type=script&lang=js&"
export * from "./articleList.vue?vue&type=script&lang=js&"
import style0 from "./articleList.vue?vue&type=style&index=0&id=19f69a62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f69a62",
  null
  
)

export default component.exports