<template>
  <div class="app-container">
    <el-backtop :visibility-height="300"></el-backtop>

    <!-- header -->
    <Navbar></Navbar>

    <div class="banner">
      <img class="banner-img" src="../../assets/img/health-bg.png" alt="" />
      <div class="head-card">
        <h3>呵护身心 健康同行</h3>
        <h6>
          我们的专业团队将为您提供个性化的健康管理方案，助您享受健康人生！
        </h6>
      </div>
    </div>

    <!-- content -->
    <div class="content">
      <div class="container-fluid animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>CHERISH YOUR HEALTH TAKE CARE OF US</span>
        </div>
        <h3 class="pp-subtitle">珍爱健康 由我们呵护</h3>
        <div class="cherish-list">
          <div class="cherish-item">
            <img src="../../assets/img/cherish (6).png" alt="" />
          </div>
          <div class="cherish-item">
            <img src="../../assets/img/cherish (5).png" alt="" />
          </div>
          <div class="cherish-item">
            <img src="../../assets/img/cherish (4).png" alt="" />
          </div>
        </div>
        <div class="cherish-list">
          <div class="cherish-item">
            <img src="../../assets/img/cherish (3).png" alt="" />
          </div>
          <div class="cherish-item">
            <img src="../../assets/img/cherish (2).png" alt="" />
          </div>
          <div class="cherish-item">
            <img src="../../assets/img/cherish (1).png" alt="" />
          </div>
        </div>
      </div>

      <div class="container-fluid wow animated fadeInUp">
        <div class="pp-title">
          <img src="../../assets/img/bm.png" alt="" />
          <span>NTRODUCTION TO HEALTH MANAGEMENT</span>
        </div>
        <h3 class="pp-subtitle">全力帮助您管理和提升健康 实现全面的身心健康</h3>
        <div class="health-list">
          <div class="health-item">
            <img src="../../assets/img/health1.png" alt="" />
            <h4>个性化健康评估</h4>
            <p>
              通过详细的个人健康评估，包括体检结果、疾病风险评估和家族病史等信息，为每位客户提供量身定制的健康管理计划。
            </p>
          </div>
          <div class="health-item">
            <img src="../../assets/img/health2.png" alt="" />
            <h4>健康检测和监测</h4>
            <p>
              利用先进的医疗设备和技术，提供全面的健康检测服务，包括血液检测、影像学检查和遗传检测等，以及定期的健康监测，确保您的身体状况得到及时监护。
            </p>
          </div>
          <div class="health-item">
            <img src="../../assets/img/health3.png" alt="" />
            <h4>营养咨询和饮食指导</h4>
            <p>
              与专业的营养师合作，为您提供个性化的营养咨询和饮食指导，制定适合您的饮食计划，帮助您实现健康的饮食习惯和营养平衡。
            </p>
          </div>
        </div>
        <div class="health-list">
          <div class="health-item">
            <img src="../../assets/img/health4.png" alt="" />
            <h4>运动指导和健身计划</h4>
            <p>
              与专业的运动教练合作，为您制定个性化的运动指导和健身计划，根据您的需求和目标，定制适合您的运动方案，提升身体健康和体能水平。
            </p>
          </div>
          <div class="health-item">
            <img src="../../assets/img/health5.png" alt="" />
            <h4>心理健康支持</h4>
            <p>
              我们提供心理健康支持，与专业心理咨询师合作，为您提供心理咨询和支持，帮助您应对压力、焦虑和情绪问题，维护心理健康和幸福感。
            </p>
          </div>
          <div class="health-item">
            <img src="../../assets/img/health6.png" alt="" />
            <h4>健康教育和培训</h4>
            <p>
              通过健康教育和培训活动，我们致力于提升客户的健康意识和健康知识，为您提供有关健康生活方式、疾病预防和健康管理的知识，使您能够做出更明智的健康决策。
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid wow animated fadeInUp">
        <div class="product">
          <img src="../../assets/img/product.jpg" alt="" />
          <!-- <div class="product-info">
            <h5>维嫄水泌时光面膜</h5>
            <h6>WEIYUAN MOISTURIZING TIME MASK</h6>
            <p>
              密集补水保湿，改善肌肤干燥粗糙问题，带给肌肤水润呵护体验，令肌肤水润细腻，滢嫩靓泽。
            </p>
            <div class="obtain">
              <span @click="handleToGoods">获取产品</span>
              <i class="el-icon-right iconRight"></i>
            </div>
            <div style="display: flex">
              <div class="shop"></div>
              <div class="shops"></div>
            </div>
          </div> -->
        </div>
        <div class="product">
          <img src="../../assets/img/mlx.jpg" alt="" />
          <!-- <div class="product-info">
            <h5>维嫄水泌时光面膜</h5>
            <h6>WEIYUAN MOISTURIZING TIME MASK</h6>
            <p>
              密集补水保湿，改善肌肤干燥粗糙问题，带给肌肤水润呵护体验，令肌肤水润细腻，滢嫩靓泽。
            </p>
            <div class="obtain">
              <span @click="handleToGoods">获取产品</span>
              <i class="el-icon-right iconRight"></i>
            </div>
            <div style="display: flex">
              <div class="shop"></div>
              <div class="shops"></div>
            </div>
          </div> -->
        </div>
        <!-- <div class="product">
          <img src="../../assets/img/product.png" alt="" />
          <div class="product-info">
            <h5>维嫄水泌时光面膜</h5>
            <h6>WEIYUAN MOISTURIZING TIME MASK</h6>
            <p>
              密集补水保湿，改善肌肤干燥粗糙问题，带给肌肤水润呵护体验，令肌肤水润细腻，滢嫩靓泽。
            </p>
            <div class="obtain">
              <span @click="handleToGoods">获取产品</span>
              <i class="el-icon-right iconRight"></i>
            </div>
            <div style="display: flex">
              <div class="shop"></div>
              <div class="shops"></div>
            </div>
          </div>
        </div> -->

        <!-- <div class="read-more">
          <span>了解更多</span>
          <i class="el-icon-right iconRight"></i>
        </div> -->
      </div>
    </div>

    <!-- content -->
    <!-- <div class="content">
      <div class="container-fluid consult wow animated fadeInUp">
        <div>
          <h4>提供专业的咨询服务</h4>
          <h4>为您提供个性化的存储方案和建议</h4>
        </div>
        <div class="learn-more">
          <span>联系我们</span>
          <i class="el-icon-right iconRight"></i>
        </div>
      </div>
    </div> -->


    <weCode></weCode>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/headers";
import Footer from "@/components/footer";
export default {
  components: { Navbar, Footer },
  data() {
    return {
      goods_url: "https://item.jd.com/10068879566972.html",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    handleToGoods() {
      window.open(this.goods_url);
    },
  },
};
</script>
<style scoped>
.app-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 385px;
  position: relative;
}
.banner-img {
  width: 100%;
  height: 100%;
}
.head-card {
  width: 1330px;
  height: 150px;
  position: absolute;
  background: url(../../assets/img/banner-bg.png) no-repeat;
  top: 125px;
  left: 0;
}
.head-card h3 {
  margin: 0;
  font-size: 54px;
  color: #fff;
  font-weight: 600;
  padding-top: 20px;
  letter-spacing: 2px;
  padding-left: 355px;
}
.head-card h6 {
  font-size: 24px;
  color: #fff;
  padding-top: 15px;
  padding-left: 355px;
}
.cherish-list {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.cherish-item {
  margin-left: 10px;
  width: 375px;
  height: 340px;
}
.cherish-item img {
  width: 100%;
  height: 100%;
}

.health-list {
  display: flex;
  justify-content: center;
}
.health-item {
  width: 265px;
  height: 270px;
  /* background: #ccc; */
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  text-align: center;
}
.health-item img {
  width: 80px;
  height: 80px;
  margin-top: 20px;
}
.health-item h4 {
  font-size: 22px;
  color: #005a7e;
  font-weight: 600;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.health-item p {
  margin: 0;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  font-weight: 600;
}

.product {
  width: 1365px;
  height: 525px;
  margin: 30px auto;
  position: relative;
  box-shadow: -5px 5px 10px -4px #e7eaf5, 5px 5px 10px -4px #e7eaf5;
  overflow: hidden;
}
.product img {
  width: 100%;
  height: 100%;
}
.shop {
  width: 100px;
  height: 100px;
  background: url(../../assets/img/shop.png);
  /* background: url(../../assets/img/shop.png); */
  background-size: 100% 100%;
  /* margin: 0 auto; */
  margin-top: 20px;
  /* margin-left: 40px; */
}
.shops {
  width: 100px;
  height: 100px;
  background: url(../../assets/img/jd.png);
  /* background: url(../../assets/img/shop.png); */
  background-size: 100% 100%;
  /* margin: 0 auto; */
  margin-top: 20px;
  margin-left: 20px;
}
.product-info {
  width: 280px;
  height: 200px;
  /* background: #ccc; */
  position: absolute;
  right: 50px;
  top: 50px;
}
.product-info h5 {
  font-size: 34px;
  color: #000;
  font-weight: 600;
  text-align: left;
  margin: 0;
}
.product-info h6 {
  font-size: 12px;
  margin: 0;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
}
.product-info p {
  margin: 0;
  margin-top: 20px;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  width: 270px;
  text-align: left;
  height: 120px;
}
.obtain {
  width: 215px;
  height: 62px;
  background-color: #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.obtain span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
}

.obtain .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.read-more {
  width: 370px;
  height: 54px;
  background-color: #1d9199;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 50px auto;
}

.read-more span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding-left: 105px;
  padding-right: 40px;
}

.read-more .iconRight {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
</style>